import React, { FC, useEffect, useState } from 'react';
import { Outlet, useParams } from "react-router-dom";
import { useSessionStore } from "../stores/sessionStore";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import "react-multi-carousel/lib/styles.css";
import 'animate.css';
import "animate.css/animate.compat.css"
import 'sweetalert2/dist/sweetalert2.css'
import '../assets/icons.min.css';
import 'tippy.js/dist/tippy.css';
import Header from './Header';
import Footer from "./Footer";
import { useApplicationStore } from '../stores/applicationStore';

interface LayoutProps {
}

const Layout: FC<LayoutProps> = () => {
    const applicationStore = useApplicationStore();
    const sessionStore = useSessionStore();
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (sessionStore.hasSession()) {
            applicationStore.fetch();
        }
    }, [])

    return (
        <>
            {
                loading ?
                    <>Loading</> :
                    <>
                        <ToastContainer />
                        <Header></Header>
                        <main data-bs-spy="scroll" data-bs-target="#main-nav" data-bs-smooth-scroll="true">
                            <Outlet />
                        </main>
                        <Footer></Footer>
                    </>
            }
        </>
    )
};

export default Layout;
