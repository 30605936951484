import React, { FC, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ApplicationDetailProps {
}

const ApplicationDetail = forwardRef<any, ApplicationDetailProps>(({ }, ref) => {
    const { t } = useTranslation();
    const myModalRef = useRef<HTMLDivElement | null>(null);
    const [application, setApplication] = useState<any>(null);

    const openModal = () => {
        if (myModalRef.current) {
            // @ts-ignore: Bootstrap method
            $(myModalRef.current).modal("show");
        }
    };

    const closeModal = () => {
        if (myModalRef.current) {
            // @ts-ignore: Bootstrap method
            $(myModalRef.current).modal("hide");
        }
    };

    const handleButtonClick = () => {
        closeModal();
    };

    useImperativeHandle(ref, () => ({
        show(application: any) {
            setApplication(application);
            openModal();
        },
        close() {
            closeModal();
        }
    }));

    return (
        <>
            <div className="modal fade" id="appDetailsModal" aria-labelledby="appDetailsModalLabel"
                ref={myModalRef}
                aria-hidden="true">
                <div className="modal-dialog modal-xl modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="appDetailsModalLabel">{t('applicationDetails')} </h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {application && (
                                <div className="application-details">
                                    <div className='row'>

                                    <div className='col-lg-12 mb-4'>
                                        <h3>{t('visaFormPage.basicInfo')}</h3>
                                        <p><strong>{t('visaFormPage.label01')}:</strong> {application.basicInformation.userFullName}</p>
                                        <p><strong>{t('visaFormPage.label02')}:</strong> {application.basicInformation.userPhone}</p>
                                        <p><strong>{t('visaFormPage.label03')}:</strong> {application.basicInformation.userEmail}</p>
                                        <p><strong>{t('visaFormPage.label04')}:</strong> {application.basicInformation.residenceCard}</p>
                                        <p><strong>{t('visaFormPage.label05')}:</strong> {application.basicInformation.mothersName}</p>
                                        <p><strong>{t('visaFormPage.label06')}:</strong> {application.basicInformation.fathersName}</p>
                                        <p><strong>{t('visaFormPage.label07')}:</strong> {application.basicInformation.gender}</p>
                                        <p><strong>{t('visaFormPage.label08')}:</strong> {application.basicInformation.nationality}</p>
                                        <p><strong>{t('visaFormPage.label09')}:</strong> {application.basicInformation.citizenship}</p>
                                        <p><strong>{t('visaFormPage.label10')}:</strong> {application.basicInformation.maidenName}</p>
                                        <p><strong>{t('visaFormPage.label11')}:</strong> {application.basicInformation.birthDate}</p>
                                        <p><strong>{t('visaFormPage.label12')}:</strong> {application.basicInformation.birthPlace}</p>
                                        <p><strong>{t('visaFormPage.label13')}:</strong> {application.basicInformation.city}</p>
                                        <p><strong>{t('visaFormPage.label14')}:</strong> {application.basicInformation.country}</p>
                                        <p><strong>{t('visaFormPage.label15')}:</strong> {application.basicInformation.address}</p>
                                        <p><strong>{t('visaFormPage.label16')}:</strong> {application.basicInformation.maritalStatus}</p>
                                        <p><strong>{t('visaFormPage.note')}:</strong> {application.basicInformation.note}</p>
                                    </div>

                                    <div className='col-lg-12 mb-4'>
                                        <h3>{t('visaFormPage.passaportInfo')}</h3>
                                        <p><strong>{t('visaFormPage.label18')}:</strong> {application.passportInformation.passportType}</p>
                                        <p><strong>{t('visaFormPage.label19')}:</strong> {application.passportInformation.passportNumber}</p>
                                        <p><strong>{t('visaFormPage.label20')}:</strong> {application.passportInformation.passportDate}</p>
                                        <p><strong>{t('visaFormPage.label21')}:</strong> {application.passportInformation.passportEndDate}</p>
                                    </div>


                                    <div className='col-lg-12 mb-4'>
                                        <h3>{t('visaFormPage.VisaInfo')}</h3>
                                        <p><strong>{t('visaFormPage.label25')}:</strong> {application.visaInfo.visaNumber}</p>
                                    </div>

                                    <div className='col-lg-12 mb-4'>
                                        {application.touristVisaInformation && (
                                            <>
                                                <h3>{t('visaFormPage.touristVisa')}</h3>
                                                <p><strong>{t('visaFormPage.label41')}:</strong> {application.touristVisaInformation.roundTripTicket}</p>
                                                <p><strong>{t('visaFormPage.label42')}:</strong> {application.touristVisaInformation.travelHealthInsurance}</p>
                                                <p><strong>{t('visaFormPage.label43')}:</strong> {application.touristVisaInformation.reasonForVisit}</p>
                                                <p><strong>{t('visaFormPage.label44')}:</strong> {application.touristVisaInformation.invitationStatus}</p>
                                                <p><strong>{t('visaFormPage.label45')}:</strong> {application.touristVisaInformation.inviterIsFullName}</p>
                                                <p><strong>{t('visaFormPage.label46')}:</strong> {application.touristVisaInformation.inviterIdNumber}</p>
                                                <p><strong>{t('visaFormPage.label47')}-1:</strong> {application.touristVisaInformation.hotelReservationStartDate}</p>
                                                <p><strong>{t('visaFormPage.label47')}-2:</strong> {application.touristVisaInformation.hotelReservationEndDate}</p>
                                                <p><strong>{t('visaFormPage.label48')}:</strong> {application.touristVisaInformation.hotelInfo}</p>
                                                <p><strong>{t('visaFormPage.label49')}:</strong> {application.touristVisaInformation.travelVisaDuration}</p>
                                            </>
                                        )}
                                    </div>

                                    <div className='col-lg-12 mb-4'>
                                        {application.familyVisaInformation && (
                                            <>
                                                <h3>{t('visaFormPage.familyVisa')}</h3>
                                                <p><strong>{t('visaFormPage.label56')}:</strong> {application.familyVisaInformation.visaSupporterClosenessDegree}</p>
                                                <p><strong>{t('visaFormPage.label57')}:</strong> {application.familyVisaInformation.visaSupporterFullName}</p>
                                                <p><strong>{t('visaFormPage.label58')}:</strong> {application.familyVisaInformation.supporterAlgIdNumber}</p>
                                                <p><strong>{t('visaFormPage.label60')}:</strong> {application.familyVisaInformation.supporterNationality}</p>
                                                <p><strong>{t('visaFormPage.label59')}:</strong> {application.familyVisaInformation.supporterBirthDate}</p>
                                                <p><strong>{t('visaFormPage.label61')}:</strong> {application.familyVisaInformation.familyVisaDuration}</p>
                                                <p><strong>{t('visaFormPage.label62')}:</strong> {application.familyVisaInformation.visaStartDate}</p>
                                            </>
                                        )}
                                    </div>


                                    <div className='col-lg-12 mb-4'>
                                        {application.workVisaInformation && (
                                            <>
                                                <h3>{t('visaFormPage.workVisa')}</h3>
                                                <p><strong>{t('visaFormPage.label70')}:</strong> {application.workVisaInformation.workAlgeriaCompanyName}</p>
                                                <p><strong>{t('visaFormPage.label71')}:</strong> {application.workVisaInformation.workAlgeriaCompanyAddress}</p>
                                                <p><strong>{t('visaFormPage.label72')}:</strong> {application.workVisaInformation.workJob}</p>
                                                <p><strong>{t('visaFormPage.label73')}:</strong> {application.workVisaInformation.workTitle}</p>
                                                <p><strong>{t('visaFormPage.label74')}:</strong> {application.workVisaInformation.workVisaDuration}</p>
                                                <p><strong>{t('visaFormPage.label75')}:</strong> {application.workVisaInformation.workVisaStartDate}</p>
                                            </>
                                        )}
                                    </div>



                                    <div className='col-lg-12 mb-4'>
                                        {application.businessVisaInformation && (
                                            <>
                                                <h3>{t('visaFormPage.businessVisa')}</h3>
                                                <p><strong>{t('visaFormPage.label28')}:</strong> {application.businessVisaInformation.businessTurkishCompanyName}</p>
                                                <p><strong>{t('visaFormPage.label29')}:</strong> {application.businessVisaInformation.businessTurkishCompanyAddress}</p>
                                                <p><strong>{t('visaFormPage.label30')}:</strong> {application.businessVisaInformation.businessAlgeriaCompanyName}</p>
                                                <p><strong>{t('visaFormPage.label31')}:</strong> {application.businessVisaInformation.businessAlgeriaCompanyAddress}</p>
                                                <p><strong>{t('visaFormPage.label32')}:</strong> {application.businessVisaInformation.businessJob}</p>
                                                <p><strong>{t('visaFormPage.label33')}:</strong> {application.businessVisaInformation.businessTitle}</p>
                                                <p><strong>{t('visaFormPage.label34')}:</strong> {application.businessVisaInformation.businessVisaDuration}</p>
                                                <p><strong>{t('visaFormPage.label35')}:</strong> {application.businessVisaInformation.businessVisaStartDate}</p>
                                            </>
                                        )}
                                    </div>

                                    <div className='col-lg-12 mb-4'>
                                        {application.transitVisaInformation && (
                                            <>
                                                <h3>{t('visaFormPage.transitVisa')}</h3>
                                                <p><strong>{t('visaFormPage.label77')}:</strong> {application.transitVisaInformation.destinationCountry}</p>
                                                <p><strong>{t('visaFormPage.label78')}:</strong> {application.transitVisaInformation.destinationCountryVisa}</p>
                                                <p><strong>{t('visaFormPage.label79')}:</strong> {application.transitVisaInformation.transitVisaStartDate}</p>
                                            </>
                                        )}
                                    </div>

                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal"
                                aria-label="Close">{t('close')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});
ApplicationDetail.displayName = 'ApplicationDetail';
export default ApplicationDetail;