import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import VisaTypeSelection from './VisaTypeSelection';
import BasicInfoForm from './BasicInfoForm';
import PassportInfoForm from './PassportInfoForm';
import TouristVisaForm from './TouristVisaForm';
import FamilyVisaForm from './FamilyVisaForm';
import WorkVisaForm from './WorkVisaForm';
import BusinessVisaForm from './BusinessVisaForm';
import TransitVisaForm from './TransitVisaForm';
import VisaInfoForm from './VisaInfoForm';
import { VisaApplication } from '../../models/VisaApplication';
import { BasicInformation } from '../../models/BasicInformation';
import { PassportInformation } from '../../models/PassportInformation';
import { VisaInfo } from '../../models/VisaInfo';
import { TouristVisaInformation } from '../../models/TouristVisaInformation';
import { FamilyVisaInformation } from '../../models/FamilyVisaInformation';
import { WorkVisaInformation } from '../../models/WorkVisaInformation';
import { BusinessVisaInformation } from '../../models/BusinessVisaInformation';
import { TransitVisaInformation } from '../../models/TransitVisaInformation';
import { ApplicationService } from '../../services/ApplicationService';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useApplicationStore } from '../../stores/applicationStore';

const VisaForm: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const applicationStore = useApplicationStore();

    const applicatoinService = new ApplicationService();

    const [visaApplication, setVisaApplication] = useState<VisaApplication>(VisaApplication.randomVisaApplication());

    const handleVisaTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setVisaApplication(new VisaApplication({
            ...visaApplication,
            visaType: event.target.value
        } as VisaApplication));
    };

    const handleBaseInformationChange = (basicInformation: BasicInformation) => {
        setVisaApplication(new VisaApplication({
            ...visaApplication,
            basicInformation
        } as VisaApplication));
    };

    const handlePassportInformationChange = (passportInformation: PassportInformation) => {
        setVisaApplication(new VisaApplication({
            ...visaApplication,
            passportInformation
        } as VisaApplication));
    };

    const handleVisaInfoChange = (visaInfo: VisaInfo) => {
        setVisaApplication(new VisaApplication({
            ...visaApplication,
            visaInfo
        } as VisaApplication));
    };

    const handleTouristVisaInformationChange = (touristVisaInformation: TouristVisaInformation) => {
        setVisaApplication(new VisaApplication({
            ...visaApplication,
            touristVisaInformation
        } as VisaApplication));
    };

    const handleFamilyVisaInformationChange = (familyVisaInformation: FamilyVisaInformation) => {
        setVisaApplication(new VisaApplication({
            ...visaApplication,
            familyVisaInformation
        } as VisaApplication));
    };

    const handleWorkVisaInformationChange = (workVisaInformation: WorkVisaInformation) => {
        setVisaApplication(new VisaApplication({
            ...visaApplication,
            workVisaInformation
        } as VisaApplication));
    };

    const handleBusinessVisaInformationChange = (businessVisaInformation: BusinessVisaInformation) => {
        setVisaApplication(new VisaApplication({
            ...visaApplication,
            businessVisaInformation
        } as VisaApplication));
    };

    const handleTransitVisaInformationChange = (transitVisaInformation: TransitVisaInformation) => {
        setVisaApplication(new VisaApplication({
            ...visaApplication,
            transitVisaInformation
        } as VisaApplication));
    };

    return (
        <div className='app-form' id='appForm'>
            <VisaTypeSelection visaType={visaApplication.visaType} handleVisaTypeChange={handleVisaTypeChange} />
            <BasicInfoForm data={visaApplication.basicInformation} onChange={handleBaseInformationChange} />
            <PassportInfoForm data={visaApplication.passportInformation} onChange={handlePassportInformationChange} />
            <VisaInfoForm data={visaApplication.visaInfo} onChange={handleVisaInfoChange} />
            {visaApplication.visaType === 'tourist' && visaApplication.touristVisaInformation && <TouristVisaForm data={visaApplication.touristVisaInformation} onChange={handleTouristVisaInformationChange} />}
            {visaApplication.visaType === 'family' && visaApplication.familyVisaInformation && <FamilyVisaForm data={visaApplication.familyVisaInformation} onChange={handleFamilyVisaInformationChange} />}
            {visaApplication.visaType === 'work' && visaApplication.workVisaInformation && <WorkVisaForm data={visaApplication.workVisaInformation} onChange={handleWorkVisaInformationChange} />}
            {visaApplication.visaType === 'business' && visaApplication.businessVisaInformation && <BusinessVisaForm data={visaApplication.businessVisaInformation} onChange={handleBusinessVisaInformationChange} />}
            {visaApplication.visaType === 'transit' && visaApplication.transitVisaInformation && <TransitVisaForm data={visaApplication.transitVisaInformation} onChange={handleTransitVisaInformationChange} />}
            <button className='button-default mt-4 w-100' onClick={() => {
                applicatoinService.sendApplication(visaApplication).then(() => {
                    toast.success('Application sent successfully');
                    applicationStore.fetch(true).then(() => {
                        navigate('/basvurular');
                    })
                }).catch(() => {
                    toast.error('Failed to send application');
                });
            }}>{t('visaFormPage.sendAppFormButton')}</button>
        </div >
    );
};

export default VisaForm;