import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import ContactSection from "../components/ContactSection";
// import Form from "../components/Form";

const CookiePolicy: React.FC = () => {
    const { t } = useTranslation(); // useTranslation hook'unu kullanarak 't' fonksiyonuna eriş

    return (
        <>
            <Helmet>
                <title>{t('cookiePolicy.cookiePolicy')}</title>
                <meta name="keywords" content={t('cookiePolicy.cookiePolicy')} />
            </Helmet>
            <section className='breadbrumb-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 breadcrumb-container'>
                            <h1>{t('cookiePolicy.cookiePolicy')}</h1>
                            <ul className="breadcrumbs">
                                <li><a href="/">{t('home')}</a></li>
                                <li>{t('cookiePolicy.cookiePolicy')}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className='content-style-01 policy-content py-6'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h1 className='d-none'>
                                {t('cookiePolicy.h1')}
                            </h1>
                            <h2>
                                {t('cookiePolicy.h2')}
                            </h2>
                            <p>

                                {t('cookiePolicy.p1')}
                            </p>
                            <p>
                                {t('cookiePolicy.p2')}
                            </p>
                            <p>
                                {t('cookiePolicy.p3')}
                            </p>
                            <p>
                                {t('cookiePolicy.p4')}
                            </p>

                            <h2>
                                {t('cookiePolicy.h2-2')}
                            </h2>
                            <p>
                                {t('cookiePolicy.p5')}
                            </p>
                            <p className='mb-0'>
                                <b>{t('cookiePolicy.p6')}</b>

                            </p>
                            <ul>
                                <li>
                                    {t('cookiePolicy.list1')}

                                </li>
                                <li>
                                    {t('cookiePolicy.list2')}

                                </li>
                                <li>
                                    {t('cookiePolicy.list3')}

                                </li>
                                <li>
                                    {t('cookiePolicy.list4')}

                                </li>
                                <li>
                                    {t('cookiePolicy.list5')}

                                </li>
                                <li>
                                    {t('cookiePolicy.list6')}

                                </li>
                                <li>
                                    {t('cookiePolicy.list7')}

                                </li>
                            </ul>
                            <p>
                                {t('cookiePolicy.p7')}

                            </p>
                            <h2>
                                {t('cookiePolicy.h2-3')}
                            </h2>

                            <div className='table-responsive'>
                                <table className='table table-bordered table-striped'>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <p><strong>
                                                    {t('cookiePolicy.col-1')}
                                                </strong></p>
                                            </td>
                                            <td>
                                                <p><strong>
                                                    {t('cookiePolicy.col-2')}
                                                </strong></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p><strong>
                                                    {t('cookiePolicy.col-3')}
                                                </strong></p>
                                            </td>
                                            <td>
                                                <p><span>
                                                    {t('cookiePolicy.col-4')}
                                                </span></p>

                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p><strong>
                                                    {t('cookiePolicy.col-5')}
                                                </strong></p>
                                            </td>
                                            <td>
                                                <p>

                                                    {t('cookiePolicy.col-6')}

                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p><strong>
                                                    {t('cookiePolicy.col-7')}
                                                </strong></p>
                                            </td>
                                            <td>

                                                <p>  {t('cookiePolicy.col-8')}
                                                </p>

                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>
                                                    <strong>
                                                        {t('cookiePolicy.col-9')}
                                                    </strong>
                                                </p>
                                            </td>
                                            <td>
                                                <p>
                                                    {t('cookiePolicy.col-10')}


                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p><strong>{t('cookiePolicy.col-11')}</strong></p>
                                            </td>
                                            <td>
                                                <p>
                                                    {t('cookiePolicy.col-12')}


                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <h2>{t('cookiePolicy.h2-4')}</h2>
                            <p>
                                {t('cookiePolicy.p8')}

                            </p>

                            <div className='table-responsive'>
                                <table className='table table-bordered table-striped'>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <p><strong>
                                                    {t('cookiePolicy.col-13')}
                                                </strong></p>
                                            </td>
                                            <td>
                                                <p>
                                                    <a href="https://support.google.com/chrome/answer/95647?hl=tr"><span>https://support.google.com/chrome/answer/95647?hl=tr</span></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p><strong>
                                                    {t('cookiePolicy.col-14')}
                                                </strong></p>
                                            </td>
                                            <td>
                                                <p><a href="https://support.microsoft.com/tr-tr/help/260971/description-of-cookies"><span>https://support.microsoft.com/tr-tr/help/260971/description-of-cookies</span></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p><strong>
                                                    {t('cookiePolicy.col-15')}
                                                </strong></p>
                                            </td>
                                            <td>
                                                <p><a href="https://support.mozilla.org/tr/kb/cerezler-web-sitelerinin-bilgisayarinizda-depoladi"><span>https://support.mozilla.org/tr/kb/cerezler-web-sitelerinin-bilgisayarinizda-depoladi</span></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p><strong>
                                                    {t('cookiePolicy.col-16')}
                                                </strong></p>
                                            </td>
                                            <td>
                                                <p><a href="https://support.apple.com/tr-tr/guide/safari/sfri11471/mac"><span>https://support.apple.com/tr-tr/guide/safari/sfri11471/mac</span></a></p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p>
                                {t('cookiePolicy.p9')}
                            </p>
                            <h2>
                                {t('cookiePolicy.h2-5')}
                            </h2>
                            <p>
                                {t('cookiePolicy.p10')}
                            </p>
                            <h2>
                                {t('cookiePolicy.h2-6')}
                            </h2>
                            <p>
                                {t('cookiePolicy.p11')}
                            </p>
                            <p className='mb-0'>
                                {t('cookiePolicy.p12')}
                            </p>
                            <ul>
                                <li>
                                    {t('cookiePolicy.list8')}

                                </li>
                                <li>
                                    {t('cookiePolicy.list9')}
                                </li>
                                <li>
                                    {t('cookiePolicy.list10')}
                                </li>
                                <li>
                                    {t('cookiePolicy.list11')}
                                </li>
                                <li>
                                    {t('cookiePolicy.list12')}
                                </li>
                                <li>
                                    {t('cookiePolicy.list13')}
                                </li>
                                <li>
                                    {t('cookiePolicy.list14')}
                                </li>
                                <li>
                                    {t('cookiePolicy.list15')}
                                </li>
                                <li>
                                    {t('cookiePolicy.list16')}
                                </li>
                            </ul>
                            <p>
                                {t('cookiePolicy.p13')}
                            </p>
                            <p>
                                {t('cookiePolicy.p14')}
                            </p>
                            <h2>
                                {t('cookiePolicy.h2-7')}
                            </h2>
                            <p>
                                {t('cookiePolicy.p15')}
                            </p>
                            <h2>
                                {t('cookiePolicy.h2-8')}
                            </h2>
                            <p>
                                {t('cookiePolicy.p16')}
                            </p>
                            <p>
                                {t('cookiePolicy.p17')}
                            </p>
                        </div>
                    </div>
                </div>
            </section>


            <ContactSection />
        </>
    )
}

export default CookiePolicy;
