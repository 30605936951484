import React, {ReactNode} from "react";
import {useMediaQuery} from "react-responsive";

interface ScreenTypeProps {
    query: { [key: string]: number };
    children: ReactNode;
}

const ScreenType = ({query, children}: ScreenTypeProps) => {
    const matches = useMediaQuery(query);
    return matches ? <>{children}</> : null;
};

export default ScreenType;
