import { UploadFile } from "../models/types/UploadFile";

const FileHelper = () => {

    const fileToBase64 = (file: File): Promise<UploadFile> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(new UploadFile(file.name, reader.result as string, file.type));
            reader.onerror = error => reject(error);
        });
    };
    
    const convertFilesToBase64 = async (files: File[]): Promise<UploadFile[]> => {
        return Promise.all(files.map(file => fileToBase64(file)));
    };
    return {
        fileToBase64,
        convertFilesToBase64
    };
}
export default FileHelper();
