import React from 'react';
import Form from '../../components/Form';
import { useTranslation } from 'react-i18next';
import ContactSection from '../../components/ContactSection';
import { Helmet } from 'react-helmet';

interface WorkingVisaProps { }


const WorkingVisa: React.FC<WorkingVisaProps> = (props) => {
    const { t } = useTranslation(); // useTranslation hook'unu kullanarak 't' fonksiyonuna eriş
    return (
        <>
            <Helmet>
                <title>{t('workVisa')}</title>
                <meta name="keywords" content="Cezayir Çalışma Vizesi" />
            </Helmet>
            <section className='breadbrumb-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 breadcrumb-container'>
                            <h1>{t('workVisa')}</h1>
                            <ul className="breadcrumbs">
                                <li><a href="/">{t('home')}</a></li>
                                <li>{t('workVisa')}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className='content-style-01 py-5 my-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h5>{t('applicationProcess')}</h5>
                            <h2>{t('whatIsAworkVisa')}</h2>
                           <p>
                           {t('workTexts.p1')}                              
                           </p>
<h3 className='mb-4'>{t('workTexts.h3')}</h3>
<ul>
    <li>
    {t('workTexts.li1')} 
    </li>
    <li>
    {t('workTexts.li2')} 
    </li>
    <li>
    {t('workTexts.li3')} 
    </li>
    <li>
    {t('workTexts.li4')} 
    </li>
    <li>
    {t('workTexts.li5')} 
    </li>
    <li>
    {t('workTexts.li6')} 
    </li>
 </ul>

                        </div>
                    </div>
                </div>
            </section>

            <section className='form-section mb-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='content-style-01'>
                            <h5>{t('haveAQuestion')}</h5>
                            <h2>{t('writeToUs')}</h2>
                            </div>
                            <Form />
                        </div>
                    </div>
                </div>
            </section>

            <ContactSection />
        </>
    );
}

export default WorkingVisa;