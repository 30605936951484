import { create } from "zustand";
import _ from "lodash";
import storageHelper from "../helpers/storageHelper";


const prefix = 'bepartners';

export interface SessionStore {
    email: string,
    setEmail: (email: string) => void,
    hasSession: () => boolean,
    login: (data: any) => void,
    logout: () => void,
    isAdmin: () => boolean,
    isActive: () => boolean,
    me: () => any,
}

export const useSessionStore = create<SessionStore>()((set, get) => {
    const parseJwt = (token: string): Record<string, unknown> | null => {
        try {
            const parts = token.split('.');
            if (parts.length !== 3) {
                throw new Error('The token is invalid');
            }

            const payload = parts[1];
            const decodedPayload = atob(payload.replace(/_/g, '/').replace(/-/g, '+'));

            return JSON.parse(decodedPayload);
        } catch (e) {
            console.error('Failed to parse JWT:', e);
            return null;
        }
    }
    return {
        email: localStorage.getItem(`${prefix}_email`) || '',
        setEmail: (email: string) => {
            set({ email })
        },
        hasSession: () => {
            const { email } = get();
            return !_.isEmpty(email) && email.length > 0;
        },
        login: (data: any) => {
            storageHelper.setToken(data.access_token)
            storageHelper.setRefreshToken(data.refresh_token)
            storageHelper.setData(`${prefix}_email`, data.email);
            set({ email: data.email })
        },
        logout: () => {
            storageHelper.removeToken()
            storageHelper.removeRefreshToken()
            storageHelper.removeData(`${prefix}_email`);
            set({ email: '' })
        },
        isAdmin: () => {
            const token = storageHelper.getToken();
            if (token) {
                const parsedToken = parseJwt(token);
                if (parsedToken) {
                    const role: string = _.get(parsedToken, 'role', '') as string;
                    if (role.length > 0)
                        return parseInt(role) <= 0;
                }
            }
            return false;
        },
        isActive: () => {
            const token = storageHelper.getToken();
            if (token) {
                const parsedToken = parseJwt(token);
                if (parsedToken) {
                    const isActive: boolean = _.get(parsedToken, 'active', false) as boolean;
                    return isActive;
                }
            }
            return false;
        },
        me: () => {
            const token = storageHelper.getToken();
            if (token) {
                const parsedToken = parseJwt(token);
                return parsedToken
            }
            return false;
        },
    }
})
