import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone';
import Tippy from '@tippyjs/react';
import _ from 'lodash';
import { TransitVisaInformation } from '../../models/TransitVisaInformation';
import moment from 'moment';
import FileUploader from '../FileUploader';

interface TransitVisaFormProps {
    data: TransitVisaInformation;
    onChange: (transitVisaInformation: TransitVisaInformation) => void;
}

const TransitVisaForm: React.FC<TransitVisaFormProps> = ({ data, onChange }) => {
    const { t } = useTranslation();

    const handleInputChange = (field: keyof TransitVisaInformation) => (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const value = event.target.type === 'number' ? parseFloat(event.target.value) : event.target.value;
        onChange(new TransitVisaInformation({
            ...data,
            [field]: value
        }));
    };

    const handleDrop = (field: keyof TransitVisaInformation) => (acceptedFiles: File[]) => {
        onChange(new TransitVisaInformation({
            ...data,
            [field]: acceptedFiles
        }));
    };

    return (
        <div className='form-box mb-4'>
            <h2><span className='step'></span>{t('visaFormPage.transitVisa')}</h2>
            <div className='row'>
                <div className='col-lg-4 mb-3'>
                    <label htmlFor="destinationCountry" className="form-label">{t('visaFormPage.label77')}
                        <Tippy content={t('tooltip.DestinationCountry')} placement="top">
                            <button className='form-tooltip'><i className="bi bi-question-circle-fill"></i></button>
                        </Tippy>
                    </label>
                    <input type="text" className="form-control" id="destinationCountry" placeholder="" value={data.destinationCountry} onChange={handleInputChange('destinationCountry')}/>
                </div>
                <div className='col-lg-4 mb-3'>
                    <label htmlFor="destinationCountryVisa" className="form-label">{t('visaFormPage.label78')}
                        <Tippy content={t('tooltip.DestinationCountryVisa')} placement="top">
                            <button className='form-tooltip'><i className="bi bi-question-circle-fill"></i></button>
                        </Tippy>
                    </label>
                    <select id="destinationCountryVisa" className="form-select" aria-label="Default select DestinationCountryVisa" value={data.destinationCountryVisa} onChange={handleInputChange('destinationCountryVisa')}>
                        <option selected>{t('visaFormPage.select')}</option>
                        <option value="1">{t('visaFormPage.available')}</option>
                        <option value="2">{t('visaFormPage.notAvailable')}</option>
                        <option value="3">{t('visaFormPage.visaExempt')}</option>
                    </select>
                </div>
                <div className='col-lg-4 mb-3'>
                    <label htmlFor="transitVisaStartDate" className="form-label">{t('visaFormPage.label79')}
                        <Tippy content={t('tooltip.transitVisaStartDate')} placement="top">
                            <button className='form-tooltip'><i className="bi bi-question-circle-fill"></i></button>
                        </Tippy>
                    </label>
                    <input type="date" className="form-control" id="transitVisaStartDate" value={moment(data.transitVisaStartDate).format('YYYY-MM-DD')} onChange={handleInputChange('transitVisaStartDate')}/>
                </div>
                <div className='col-lg-12 mb-3'>
                    <label htmlFor="destinationCountryVisaCopy" className="form-label">{t('visaFormPage.label80')}</label>
                    <FileUploader onChange={handleDrop('destinationCountryVisaCopy')} label={t('visaFormPage.fileUploadTitle')} />
                </div>
            </div>
        </div>
    );
};

export default TransitVisaForm;