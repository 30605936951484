import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import Form from '../components/Form';
import ContactSection from "../components/ContactSection";
import { UserService } from '../services/UserService';
import { useSessionStore } from '../stores/sessionStore';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthenticationService } from '../services/AuthenticationService';
import { useApplicationStore } from '../stores/applicationStore';
import { set } from 'lodash';
import moment from 'moment';
import _ from 'lodash';
import ApplicationDetail from '../components/modals/ApplicationDetail';

const TrackApplication: React.FC = () => {
    const { t } = useTranslation(); // useTranslation hook'unu kullanarak 't' fonksiyonuna eriş
    const navigate = useNavigate()
    const userService = new UserService();
    const sessionStore = useSessionStore();
    const [user, setUser] = useState<any>(null)
    const applicationStore = useApplicationStore();
    const [applications, setApplications] = useState<any[]>([])

    useEffect(() => {
        userService.fetchAccount().then(user => {
            setUser(user)
        })
        applicationStore.fetch().then((data) => {
            setApplications(data)
        })
    }, []);


    const applicationModal = useRef<{
        show: (application: any) => void,
        close: () => void,
    }>();

    return (
        <>
            <ApplicationDetail ref={applicationModal} />
            <Helmet>
                <title>Be Visa {t('applicationTracking')}</title>
                <meta name="keywords" content={`Be Visa ${t('applicationTracking')}`} />
            </Helmet>
            <section className='breadbrumb-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 breadcrumb-container'>
                            <h1>{t('applicationTracking')}</h1>
                            <ul className="breadcrumbs">
                                <li><a href="/">{t('home')}</a></li>
                                <li>{t('applicationTracking')}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className='content-style-01 py-6'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <a href="/logout" title={t('logout')} className="button-default mb-4">{t('logout')}</a>
                            <h5>{t('processTracking')}</h5>
                            <h2>{t('yourApplications')}</h2>

                            <div className='table-responsive w-100'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">{t('date')}</th>
                                            <th scope="col">{t('fullname')}</th>
                                            <th scope="col">{t('visaType')}</th>
                                            <th scope="col">{t('status')}</th>
                                            <th scope="col">{t('details')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            applications.map((application, index) => {
                                                return (
                                                    <tr key={_.uniqueId('application_')} className='align-middle'>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>{moment(application.createdAt).format('DD.MM.YYYY')}</td>
                                                        <td>{application.basicInformation.userFullName}</td>
                                                        <td>{t(`${application.visaType}Visa`)}</td>
                                                        <td><span className={`badge text-bg-warning`}>{t(application.status)}</span></td>
                                                        <td>
                                                            <button type="button" className="btn btn-primary" onClick={() => {
                                                                applicationModal.current?.show(application);
                                                            }}>{t('details')}
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='form-section mb-5 pb-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='content-style-01'>
                                <h5>{t('haveAQuestion')}</h5>
                                <h2>{t('writeToUs')}</h2>
                            </div>
                            <Form />
                        </div>
                    </div>
                </div>
            </section>

            <ContactSection />
        </>
    );
};

export default TrackApplication;
