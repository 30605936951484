import { faker } from '@faker-js/faker';
import { KeyFilePair } from './types/KeyFilePair';
import fileHelper from '../helpers/fileHelper';

export class WorkVisaInformation {
    workAlgeriaCompanyName!: string;
    workAlgeriaCompanyAddress!: string;
    workJob!: string;
    workTitle!: string;
    workVisaDuration!: string;
    workVisaStartDate!: string;
    workVisaDocs!: File[];

    public constructor(init?: Partial<WorkVisaInformation>) {
        Object.assign(this, init);
    }

    validate(): string[] {
        const errors: string[] = [];

        if (!this.workAlgeriaCompanyName) {
            errors.push("Work Algeria company name is required");
        }

        if (!this.workAlgeriaCompanyAddress) {
            errors.push("Work Algeria company address is required");
        }

        if (!this.workJob) {
            errors.push("Work job is required");
        }

        if (!this.workTitle) {
            errors.push("Work title is required");
        }

        if (!this.workVisaDuration) {
            errors.push("Work visa duration is required");
        }

        if (!this.workVisaStartDate) {
            errors.push("Work visa start date is required");
        }

        return errors;
    }

    static randomWorkVisaInfo(): WorkVisaInformation {
        return new WorkVisaInformation({
            workAlgeriaCompanyName: faker.company.name(),
            workAlgeriaCompanyAddress: faker.address.country(),
            workJob: faker.name.jobTitle(),
            workTitle: faker.name.jobType(),
            workVisaDuration: faker.helpers.arrayElement(['1 month', '3 months', '6 months', '1 year']),
            workVisaStartDate: faker.date.future().toISOString().split('T')[0],
            workVisaDocs: [] // workVisaDocs (empty array for simplicity)
        });
    }

    toObject(): any {
        return {
            workAlgeriaCompanyName: this.workAlgeriaCompanyName,
            workAlgeriaCompanyAddress: this.workAlgeriaCompanyAddress,
            workJob: this.workJob.trim(),
            workTitle: this.workTitle.trim(),
            workVisaDuration: this.workVisaDuration.trim(),
            workVisaStartDate: this.workVisaStartDate.trim(),
        }
    }

    async files(): Promise<KeyFilePair[]> {
        return [new KeyFilePair('workVisaDocs', await fileHelper.convertFilesToBase64(this.workVisaDocs))];
    }
}