import React from 'react';
import {Helmet} from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Form from "../components/Form";

const Contact: React.FC = () => {
    const { t } = useTranslation(); // useTranslation hook'unu kullanarak 't' fonksiyonuna eriş
    return (
        <>
            <Helmet>
                <title>{t('contactUs')}</title>
                <meta name="keywords" content="Be Partners İletişim, Be Partners telefon"/>
            </Helmet>
            <section className='breadbrumb-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 breadcrumb-container'>
                            <h1>{t('contactUs')}</h1>
                            <ul className="breadcrumbs">
                                <li><a href="/">{t('home')}</a></li>
                                <li>{t('contact')}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section id='contact' className='contact-page-section my-5 py-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 pe-lg-4'>
                            <div className='content-style-01'>
                                <h5>{t('beVisa')}</h5>
                                <h2>{t('contactUs')}</h2>
                            </div>
                            <Form/>
                        </div>

                        <div className='col-lg-6 ps-lg-4'>
                            <div className='content-style-01'>
                                <h5>{t('weAreOnTheMap')}</h5>
                                <h2>{t('contactUs')}</h2>
                            </div>

                            <iframe
                                title='Harita'
                                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3060.6601795213214!2d32.812172075985735!3d39.90423997152653!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMznCsDU0JzE1LjMiTiAzMsKwNDgnNTMuMSJF!5e0!3m2!1str!2str!4v1723832208164!5m2!1str!2str"
                                width="100%"
                                height="400"
                                style={{border: 0}}
                                allowFullScreen={true}
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                            ></iframe>
                            <p>{t('addressText')}</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Contact;
