import {create} from "zustand";
import _ from "lodash";
import { ApplicationService } from "../services/ApplicationService";

interface ApplicationStore {
    loading: boolean,
    service: ApplicationService,
    applications: any[],
    fetch: (refresh?: boolean) => Promise<any[]>
    fetchById: (id: string) => any
}

export const useApplicationStore = create<ApplicationStore>()((set, get) => ({
    loading: false,
    service: new ApplicationService(),
    applications: [],
    fetch: async (refresh?: boolean): Promise<any[]> => {
        let {applications, loading, service} = get();
        let fetchedData: any[] = [];
        if (!loading) {
            if (applications.length <= 0 || refresh) {
                fetchedData = await service.fetchAll();
                fetchedData = _.orderBy(fetchedData, ['createdAt'], ['desc'])
                applications = fetchedData;
                set({applications: fetchedData, loading: false})
            }
        }
        return Promise.resolve(applications);
    },
    fetchById: (id: string): any => {
        let {applications, loading, service} = get();
        return applications.find(x => x.id === id);
    },
}))
