import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone';
import Tippy from '@tippyjs/react';
import _ from 'lodash';
import { WorkVisaInformation } from '../../models/WorkVisaInformation';
import moment from 'moment';
import FileUploader from '../FileUploader';

interface WorkVisaFormProps {
    data: WorkVisaInformation;
    onChange: (workVisaInformation: WorkVisaInformation) => void;
}

const WorkVisaForm: React.FC<WorkVisaFormProps> = ({ data, onChange }) => {
    const { t } = useTranslation();

    const handleInputChange = (field: keyof WorkVisaInformation) => (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const value = event.target.type === 'number' ? parseFloat(event.target.value) : event.target.value;
        onChange(new WorkVisaInformation({
            ...data,
            [field]: value
        }));
    };

    const handleDrop = (field: keyof WorkVisaInformation) => (acceptedFiles: File[]) => {
        onChange(new WorkVisaInformation({
            ...data,
            [field]: acceptedFiles
        }));
    };

    return (
        <div className='form-box mb-4'>
            <h2><span className='step'></span>{t('visaFormPage.workVisa')}</h2>
            <div className='row'>
                <div className='col-lg-4 mb-3'>
                    <label htmlFor="workAlgeriaCompanyName" className="form-label">{t('visaFormPage.label70')}</label>
                    <input type="text" className="form-control" id="workAlgeriaCompanyName" placeholder="" value={data.workAlgeriaCompanyName} onChange={handleInputChange('workAlgeriaCompanyName')} />
                </div>
                <div className='col-lg-4 mb-3'>
                    <label htmlFor="workAlgeriaCompanyAddress" className="form-label">{t('visaFormPage.label71')}</label>
                    <input type="text" className="form-control" id="workAlgeriaCompanyAddress" placeholder="" value={data.workAlgeriaCompanyAddress} onChange={handleInputChange('workAlgeriaCompanyAddress')} />
                </div>
                <div className='col-lg-4 mb-3'>
                    <label htmlFor="workJob" className="form-label">{t('visaFormPage.label72')}</label>
                    <input type="text" className="form-control" id="workJob" placeholder="" value={data.workJob} onChange={handleInputChange('workJob')} />
                </div>
                <div className='col-lg-4 mb-3'>
                    <label htmlFor="workTitle" className="form-label">{t('visaFormPage.label73')}</label>
                    <input type="text" className="form-control" id="workTitle" placeholder="" value={data.workTitle} onChange={handleInputChange('workTitle')} />
                </div>
                <div className='col-lg-4 mb-3'>
                    <label htmlFor="workVisaDuration" className="form-label">{t('visaFormPage.label74')}</label>
                    <select id='workVisaDuration' className="form-select" aria-label="Default select workVisaDuration" value={data.workVisaDuration} onChange={handleInputChange('workVisaDuration')}>
                        <option selected>{t('visaFormPage.select')}</option>
                        <option value="1">{t('visaFormPage.1Month')}</option>
                        <option value="2">{t('visaFormPage.3Months')}</option>
                    </select>
                </div>
                <div className='col-lg-4 mb-3'>
                    <label htmlFor="workVisaStartDate" className="form-label">{t('visaFormPage.label75')}</label>
                    <input type="date" className="form-control" id="workVisaStartDate" placeholder="" value={moment(data.workVisaStartDate).format('YYYY-MM-DD')} onChange={handleInputChange('workVisaStartDate')} />
                </div>
                <div className='col-lg-12 mb-3'>
                    <label htmlFor="workVisaDocs" className="form-label">{t('visaFormPage.label76')}</label>
                    <FileUploader onChange={handleDrop('workVisaDocs')} label={t('visaFormPage.fileUploadTitle')} />
                </div>
            </div>
        </div>
    );
};

export default WorkVisaForm;