import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import ContactSection from "../components/ContactSection";


const Terms: React.FC = () => {
    const { t } = useTranslation(); // useTranslation hook'unu kullanarak 't' fonksiyonuna eriş

    return (
        <>
            <Helmet>
                <title>{t('terms')}</title>
                <meta name="keywords" content={t('terms')} />
            </Helmet>
            <section className='breadbrumb-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 breadcrumb-container'>
                            <h1>{t('terms')}</h1>
                            <ul className="breadcrumbs">
                                <li><a href="/static">{t('home')}</a></li>
                                <li>{t('terms')}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className='content-style-01 policy-content py-6'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <p>{t('termsOfUse.p1')}</p>
                            <p>{t('termsOfUse.p2')}</p>
                            <p>{t('termsOfUse.p3')}</p>
                            <h2>{t('termsOfUse.h2-1')}</h2>
                            <p>{t('termsOfUse.p4')}</p>
                            <p>{t('termsOfUse.p5')}</p>
                            <p>{t('termsOfUse.p6')}</p>
                            <p>{t('termsOfUse.p7')}</p>
                            <p>{t('termsOfUse.p8')}</p>
                            <h2>{t('termsOfUse.h2-2')}</h2>
                            <p>{t('termsOfUse.p9')}</p>
                            <p>{t('termsOfUse.p10')}</p>
                            <p>{t('termsOfUse.p11')}</p>
                            <p>{t('termsOfUse.p12')}</p>
                            <p>{t('termsOfUse.p13')}</p>
                            <p>{t('termsOfUse.p14')}</p>
                            <p>{t('termsOfUse.p15')}</p>
                            <h2>{t('termsOfUse.h2-3')}</h2>
                            <p>{t('termsOfUse.p16')}</p>
                            <p>{t('termsOfUse.p17')}</p>
                            <p>{t('termsOfUse.p18')}</p>
                            <h2>{t('termsOfUse.h2-4')}</h2>
                            <p>{t('termsOfUse.p19')}</p>
                            <p>{t('termsOfUse.p20')}</p>
                            <p>{t('termsOfUse.p21')}</p>
                            <p>{t('termsOfUse.p22')}</p>
                            <p>{t('termsOfUse.p23')}</p>
                            <p>{t('termsOfUse.p24')}</p>
                            <p>{t('termsOfUse.p25')}</p>
                            <h2>{t('termsOfUse.h2-5')}</h2>
                            <p>{t('termsOfUse.p26')}</p>
                            <p>{t('termsOfUse.p27')}</p>
                            <p>{t('termsOfUse.p28')}</p>
                            <p>{t('termsOfUse.p29')}</p>
                            <p>{t('termsOfUse.p30')}</p>
                            <p>{t('termsOfUse.p31')}</p>
                            <p>{t('termsOfUse.p32')}</p>
                            <h2>{t('termsOfUse.h2-6')}</h2>
                            <p>{t('termsOfUse.p33')}</p>
                            <p>{t('termsOfUse.p34')}</p>
                            <p>{t('termsOfUse.p35')}</p>
                        </div>
                    </div>
                </div>
            </section>


            <ContactSection />
        </>
    )
}

export default Terms;
