import axios, {AxiosError} from 'axios'
import storageHelper from "../helpers/storageHelper";

const ApiClient = () => {
    const instance = axios.create()
    instance.interceptors.request.use(async (request) => {
        const token = storageHelper.getToken();
        // Add Authorization header to every request if token exists
        if (token) {
            request.headers["Authorization"] = `Bearer ${token}`;
        }      
        request.headers['App-Type'] = 'frontend';

        return request
    })

    instance.interceptors.response.use(
        (response) => {
            return response
        },
        ({code, message, request, response}: AxiosError) => {
            return response?.data;
        }
    )

    return instance
}

export default ApiClient();
