import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone';
import { BasicInformation } from '../../models/BasicInformation';
import Tippy from '@tippyjs/react';
import moment from 'moment';
import FileUploader from '../FileUploader';

interface BasicInfoFormProps {
    data: BasicInformation;
    onChange: (basicInformation: BasicInformation) => void;
}

const BasicInfoForm: React.FC<BasicInfoFormProps> = ({ data, onChange }) => {
    const { t } = useTranslation();

    const handleInputChange = (field: keyof BasicInformation) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const value = event.target.value;
        onChange(new BasicInformation({
            ...data,
            [field]: value
        }));
    };

    const handleDrop = (field: keyof BasicInformation) => (acceptedFiles: File[]) => {
        onChange(new BasicInformation({
            ...data,
            [field]: acceptedFiles
        }));
    };

    return (
        <div className='form-box mb-4'>
            <h2><span className='step'></span>{t('visaFormPage.basicInfo')}</h2>
            <div className='row'>
                <div className='col-lg-3 mb-3'>
                    <label htmlFor="userFullName" className="form-label">{t('visaFormPage.label01')}</label>
                    <input type="text" className="form-control" id="userFullName" placeholder="" value={data.userFullName} onChange={handleInputChange('userFullName')} />
                </div>
                <div className='col-lg-3 mb-3'>
                    <label htmlFor="userPhone" className="form-label">{t('visaFormPage.label02')}
                    </label>
                    <input type="text" className="form-control" id="userPhone" placeholder="" value={data.userPhone} onChange={handleInputChange('userPhone')} />
                </div>
                <div className='col-lg-3 mb-3'>
                    <label htmlFor="userEmail" className="form-label">{t('visaFormPage.label03')}</label>
                    <input type="text" className="form-control" id="userEmail" placeholder="" value={data.userEmail} onChange={handleInputChange('userEmail')} />
                </div>
                <div className='col-lg-3 mb-3'>
                    <label htmlFor="residenceCard" className="form-label">{t('visaFormPage.label04')}
                        <Tippy content={t('tooltip.residenceCard')} placement="top">
                            <button className='form-tooltip'><i className="bi bi-question-circle-fill"></i></button>
                        </Tippy>
                    </label>
                    <input type="text" className="form-control" id="residenceCard" placeholder="" value={data.residenceCard} onChange={handleInputChange('residenceCard')} />
                </div>
                <div className='col-lg-3 mb-3'>
                    <label htmlFor="mothersName" className="form-label">{t('visaFormPage.label05')}</label>
                    <input type="text" className="form-control" id="mothersName" placeholder="" value={data.mothersName} onChange={handleInputChange('mothersName')} />
                </div>
                <div className='col-lg-3 mb-3'>
                    <label htmlFor="fathersName" className="form-label">{t('visaFormPage.label06')}</label>
                    <input type="text" className="form-control" id="fathersName" value={data.fathersName} onChange={handleInputChange('fathersName')} />
                </div>
                <div className='col-lg-3 mb-3'>
                    <label htmlFor="sex" className="form-label">{t('visaFormPage.label07')}</label>
                    <select id='sex' className="form-select" aria-label="Default select gender" value={data.gender} onChange={handleInputChange('gender')} >
                        <option selected>{t('visaFormPage.select')}</option>
                        <option value="male">{t('visaFormPage.male')}</option>
                        <option value="female">{t('visaFormPage.female')}</option>
                    </select>
                </div>
                <div className='col-lg-3 mb-3'>
                    <label htmlFor="nationality" className="form-label">{t('visaFormPage.label08')}</label>
                    <input type="text" className="form-control" id="nationality" placeholder="" value={data.nationality} onChange={handleInputChange('nationality')} />
                </div>
                <div className='col-lg-3 mb-3'>
                    <label htmlFor="citizenship" className="form-label">{t('visaFormPage.label09')}</label>
                    <input type="text" className="form-control" id="citizenship" placeholder="" value={data.citizenship} onChange={handleInputChange('citizenship')} />
                </div>
                <div className='col-lg-3 mb-3'>
                    <label htmlFor="youthSurname" className="form-label">{t('visaFormPage.label10')}
                        <Tippy content={t('tooltip.youthSurname')} placement="top">
                            <button className='form-tooltip'><i className="bi bi-question-circle-fill"></i></button>
                        </Tippy>
                    </label>
                    <input type="text" className="form-control" id="maidenName" value={data.maidenName} onChange={handleInputChange('maidenName')} />
                </div>
                <div className='col-lg-3 mb-3'>
                    <label htmlFor="birthDate" className="form-label">{t('visaFormPage.label11')}
                    </label>
                    <input type="date" className="form-control" id="birthDate" placeholder="" value={moment(data.birthDate).format('YYYY-MM-DD')} onChange={handleInputChange('birthDate')} />
                </div>
                <div className='col-lg-3 mb-3'>
                    <label htmlFor="birthPlace" className="form-label">{t('visaFormPage.label12')}</label>
                    <input type="text" className="form-control" id="birthPlace" placeholder="" value={data.birthPlace} onChange={handleInputChange('birthPlace')} />
                </div>
                <div className='col-lg-3 mb-3'>
                    <label htmlFor="city" className="form-label">{t('visaFormPage.label13')}</label>
                    <input type="text" className="form-control" id="city" placeholder="" value={data.city} onChange={handleInputChange('city')} />
                </div>
                <div className='col-lg-3 mb-3'>
                    <label htmlFor="country" className="form-label">{t('visaFormPage.label14')}</label>
                    <input type="text" className="form-control" id="country" placeholder="" value={data.country} onChange={handleInputChange('country')} />
                </div>
                <div className='col-lg-3 mb-3'>
                    <label htmlFor="address" className="form-label">{t('visaFormPage.label15')}</label>
                    <input type="text" className="form-control" id="address" placeholder="" value={data.address} onChange={handleInputChange('address')} />
                </div>
                <div className='col-lg-3 mb-3'>
                    <label htmlFor="maritalStatus" className="form-label">{t('visaFormPage.label16')}</label>
                    <select id='maritalStatus' className="form-select" aria-label="Default select maritalStatus" value={data.maritalStatus} onChange={handleInputChange('maritalStatus')}>
                        <option selected>{t('visaFormPage.select')}</option>
                        <option value="married">{t('visaFormPage.married')}</option>
                        <option value="single">{t('visaFormPage.single')}</option>
                    </select>
                </div>
                <div className='col-lg-12 mb-3'>
                    <label htmlFor="residenceCardCopy" className="form-label">{t('visaFormPage.label17')}</label>
                    <FileUploader onChange={handleDrop('residenceCardCopy')} label={t('visaFormPage.fileUploadTitle')} />
                </div>
                <div className='col-lg-12 mb-3'>
                    <label htmlFor="note" className="form-label">{t('visaFormPage.note')}</label>
                    <textarea className="form-control" id="note" placeholder="" value={data.note} onChange={handleInputChange('note')} />
                </div>
            </div>
        </div>
    );
};

export default BasicInfoForm;