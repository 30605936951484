import { faker } from '@faker-js/faker';
import { KeyValuePair } from './types/KeyValuePair';
import { KeyFilePair } from './types/KeyFilePair';
import fileHelper from '../helpers/fileHelper';

export class PassportInformation {
    passportType!: string;
    passportNumber!: string;
    passportDate!: string;
    passportEndDate!: string;
    passportCopy!: File[];

    public constructor(init?: Partial<PassportInformation>) {
        Object.assign(this, init);
    }

    validate(): string[] {
        const errors: string[] = [];

        if (!this.passportType) {
            errors.push("Passport type is required");
        }

        if (!this.passportNumber) {
            errors.push("Passport number is required");
        }

        if (!this.passportDate) {
            errors.push("Passport date is required");
        }

        if (!this.passportEndDate) {
            errors.push("Passport end date is required");
        }

        return errors;
    }

    static randomPassportInfo(): PassportInformation {
        return new PassportInformation({
            passportType: faker.helpers.arrayElement(['Regular', 'Diplomatic', 'Official']), // passportType
            passportNumber: faker.random.alphaNumeric(9), // passportNumber
            passportDate: faker.date.past(10).toISOString().split('T')[0], // passportDate
            passportEndDate: faker.date.future(10).toISOString().split('T')[0], // passportEndDate
            passportCopy: [] // passportCopy (empty array for simplicity)
        });
    }

    toObject(): any {
        return {
            passportType: this.passportType.trim(),
            passportNumber: this.passportNumber.trim(),
            passportDate: this.passportDate.trim(),
            passportEndDate: this.passportEndDate.trim(),
        }
    }

    async files(): Promise<KeyFilePair[]> {
        return [new KeyFilePair('passportCopy', await fileHelper.convertFilesToBase64(this.passportCopy))];
    }
}