const StorageHelper = () => {

    const prefix = 'bepartners';

    const isBrowser = typeof window !== 'undefined'

    const MENU_OPEN = 'menu_open';

    const getMenuOpen = (): string => {
        let savedMenuOpen = 'open';
        if (isBrowser) {
            savedMenuOpen = localStorage.getItem(MENU_OPEN) ?? 'open';
        }
        return savedMenuOpen;
    }

    const setMenuOpen = (menuOpen: string): void => {
        if (isBrowser) {
            localStorage.setItem(MENU_OPEN, menuOpen);
        }
    }

    const TOKEN = `${prefix}_token`;
    const REFRESH_TOKEN = `${prefix}_refresh_token`;

    const setToken = (token: string): void => {
        localStorage.setItem(TOKEN, token);
    }
    const getToken = (): string | null => {
        let token = localStorage.getItem(TOKEN);
        if (token && token.length > 0) {
            return token;
        }
        return null;
    }
    const removeToken = (): void => {
        localStorage.removeItem(TOKEN);
    }

    const setRefreshToken = (token: string): void => {
        localStorage.setItem(REFRESH_TOKEN, token);
    }
    const getRefreshToken = (): string | null => {
        let token = localStorage.getItem(REFRESH_TOKEN);
        if (token && token.length > 0) {
            return token;
        }
        return null;
    }
    const removeRefreshToken = (): void => {
        localStorage.removeItem(REFRESH_TOKEN);
    }

    const setData = (key: string, data: any): void => {
        localStorage.setItem(key, data);
    }
    const getData = (key: string): string | null => {
        let data = localStorage.getItem(key);
        if (data && data.length > 0) {
            return data;
        }
        return null;
    }
    const removeData = (key: string): void => {
        localStorage.removeItem(key);
    }

    return {
        setMenuOpen: setMenuOpen,
        getMenuOpen: getMenuOpen,
        setToken: setToken,
        getToken: getToken,
        removeToken: removeToken,
        setRefreshToken: setRefreshToken,
        getRefreshToken: getRefreshToken,
        removeRefreshToken: removeRefreshToken,
        setData: setData,
        getData: getData,
        removeData: removeData
    };
}
export default StorageHelper();
