import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import ContactSection from "../components/ContactSection";
// import Form from "../components/Form";

const PrivacyPolicy: React.FC = () => {
    const { t } = useTranslation(); // useTranslation hook'unu kullanarak 't' fonksiyonuna eriş

    return (
        <>
            <Helmet>
                <title>{t('privacyPolicy.privacyPolicy')}</title>
                <meta name="keywords" content={t('privacyPolicy.privacyPolicy')} />
            </Helmet>
            <section className='breadbrumb-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 breadcrumb-container'>
                            <h1>{t('privacyPolicy.privacyPolicy')}</h1>
                            <ul className="breadcrumbs">
                                <li><a href="/">{t('home')}</a></li>
                                <li>{t('privacyPolicy.privacyPolicy')}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className='content-style-01 policy-content py-6'>
                <div className='container'>
                    <div className='row'>
                    <div className='col-lg-12'>
                        <h1 className='d-none'>{t('privacyPolicy.privacyPolicy')}</h1>
<p>{t('privacyPolicy.p1')}</p>

<h2>{t('privacyPolicy.h2-1')}</h2>

<p>{t('privacyPolicy.p2')}</p>
<h2>{t('privacyPolicy.h2-2')}</h2>
<p>{t('privacyPolicy.p3')}</p>
<p>{t('privacyPolicy.p4')}</p>
<p>{t('privacyPolicy.p5')}</p>
<p>{t('privacyPolicy.p6')}</p>
<p>{t('privacyPolicy.p7')}</p>
<p>{t('privacyPolicy.p8')}</p>
<p>{t('privacyPolicy.p9')}</p>
<h2>{t('privacyPolicy.h2-3')}</h2>


<p>{t('privacyPolicy.p10')}</p>
<p>{t('privacyPolicy.p11')}</p>
<ul>
<li >{t('privacyPolicy.li-1')}</li>
<li >{t('privacyPolicy.li-2')}</li>
<li >{t('privacyPolicy.li-3')}</li>
<li >{t('privacyPolicy.li-4')}</li>
</ul>
<p>{t('privacyPolicy.p12')}</p>
<h2>{t('privacyPolicy.h2-4')}</h2>
<p>{t('privacyPolicy.p13')}</p>
<p><b>{t('privacyPolicy.p14')}</b></p>
<p>{t('privacyPolicy.p15')}</p>

<p><b>{t('privacyPolicy.p16')}</b></p>
<p>{t('privacyPolicy.p17')}</p>

<p><b>{t('privacyPolicy.p18')}</b></p>

<p>{t('privacyPolicy.p19')}</p>

<p><b>{t('privacyPolicy.p20')}</b></p>
<p>{t('privacyPolicy.p21')}</p>

<p><b>{t('privacyPolicy.p22')}</b></p>
<p>{t('privacyPolicy.p23')}</p>
<h2>{t('privacyPolicy.h2-5')}</h2>


<p>{t('privacyPolicy.p24')}</p>
<p>{t('privacyPolicy.p25')}</p>
<p>{t('privacyPolicy.p26')}</p>

<h2>{t('privacyPolicy.h2-6')}</h2>
<p>{t('privacyPolicy.p27')}</p>

<h2>{t('privacyPolicy.h2-7')}</h2>
<p>{t('privacyPolicy.p28')}</p>
<p>{t('privacyPolicy.p29')}</p>

<h2>{t('privacyPolicy.h2-8')}</h2>
<p>{t('privacyPolicy.p30')}</p>


<h3>{t('privacyPolicy.h2-8-1')}</h3>
<p>{t('privacyPolicy.p31')}</p>
<p>{t('privacyPolicy.p32')}</p>
<p>{t('privacyPolicy.p33')}</p>
<p>{t('privacyPolicy.p34')}</p>

<h2>{t('privacyPolicy.h2-9')}</h2>
<p>{t('privacyPolicy.p35')}</p>
<h2>{t('privacyPolicy.h2-10')}</h2>

<p>{t('privacyPolicy.p36')}</p>
<p>{t('privacyPolicy.p37')}</p>
<h2>{t('privacyPolicy.h2-11')}</h2>
<p>{t('privacyPolicy.p38')}</p>
<ul>
<li>
{t('privacyPolicy.li-5')}
</li>
<li>
{t('privacyPolicy.li-6')}
 </li>
<li>
{t('privacyPolicy.li-7')}
</li>
</ul>
<p>{t('privacyPolicy.p39')}</p>



<h2>{t('privacyPolicy.h2-12')}</h2>
<p>{t('privacyPolicy.p40')}</p>
<p className='mb-0'><b>{t('privacyPolicy.p41')}</b></p>
<p>{t('privacyPolicy.p42')}</p>
<p className='mb-0'><b>{t('privacyPolicy.p43')}</b></p>
<p>{t('privacyPolicy.p44')}</p>
<p className='mb-0'><b>{t('privacyPolicy.p45')}</b></p>
<p>{t('privacyPolicy.p46')}</p>
<p className='mb-0'><b>{t('privacyPolicy.p47')}</b></p>
<p>{t('privacyPolicy.p48')}</p>
<p className='mb-0'><b>{t('privacyPolicy.p49')}</b></p>
<p>{t('privacyPolicy.p50')}</p>
<p className='mb-0'><b>{t('privacyPolicy.p51')}</b></p>
<p>{t('privacyPolicy.p52')}</p>
<p>{t('privacyPolicy.p53')}</p>
<p>{t('privacyPolicy.p54')}</p>
<p className='mb-0'><b>{t('privacyPolicy.p55')}</b></p>
<p>{t('privacyPolicy.p56')}</p>
<p className='mb-0'><b>{t('privacyPolicy.p57')}</b></p>
<p>{t('privacyPolicy.p58')}</p>
<p className='mb-0'><b>{t('privacyPolicy.p59')}</b></p>
<p>{t('privacyPolicy.p60')}</p>
<p>{t('privacyPolicy.p61')}</p>
<p className='mb-0'><b>{t('privacyPolicy.p62')}</b></p>
<p>{t('privacyPolicy.p63')}</p>
<p>{t('privacyPolicy.p64')}</p>
                        </div>
                    </div>
                   </div>
              
       
            </section>


            <ContactSection />
        </>
    )
}

export default PrivacyPolicy;
