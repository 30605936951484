import { AxiosResponse } from "axios";
import apiClient from "../http/apiClient";
import { BaseService } from "./BaseService";
import { VisaApplication } from "../models/VisaApplication";
import _ from "lodash";
import ZipHelper from "../helpers/zipHelper";

export class ApplicationService extends BaseService {
    constructor(props?: any) {
        super(props);
    }

    async fetchAll(): Promise<any> {
        try {
            let response: AxiosResponse = await apiClient.get(`${process.env.REACT_APP_BASE_URL}/application`);
            if (response.status === 200 && _.isArray(response.data)) {
                return response.data;
            }
        } catch (e) {
            return Promise.reject('failed request');
        }
        return Promise.reject('failed request');
    }

    async sendApplication(application: VisaApplication): Promise<any> {
        try {
            const obj = await application.toObject();
            const compressedBody = ZipHelper.compress(JSON.stringify(obj));
            const response: AxiosResponse = await apiClient.post(
                `${process.env.REACT_APP_BASE_URL}/application`,
                compressedBody
            );
            if (response.status === 201) {
                return Promise.resolve(response.data);
            }
        } catch (e) {
            return Promise.reject('Failed to send application');
        }
        return Promise.reject('Failed to send application');
    }

}
