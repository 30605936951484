import React from 'react';
import {Helmet} from 'react-helmet';
import ContactSection from "../components/ContactSection";

const Services: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>Nova Services</title>
                <meta name="keywords" content="Nova Services"/>
            </Helmet>

            <section className='breadbrumb-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 breadcrumb-container'>
                            <h1>Our Services</h1>
                            <ul className="breadcrumbs">
                                <li><a href="/">Home</a></li>
                                <li>Services</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className='content-style-02 py-5 my-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-5'>
                            <h2>Creative Solutions, Powerful Impact</h2>
                        </div>
                        <div className='col-lg-7'>
                            <p>
                                We specialize in web development, graphic design, SEO, and video promos, all crafted to
                                enhance your brand’s presence and success. Our team delivers cutting-edge solutions and
                                creative strategies tailored to the fast-paced crypto industry. From eye-catching
                                visuals to optimized search engine performance, we ensure every aspect of your digital
                                presence is covered. Collaborate with us for innovative, results-driven campaigns that
                                elevate your brand and drive measurable growth.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section id='features' className='key-features-section pb-5'>
                <div className='container'>
                    <div className="row justify-content-center items">
                        <div className="col-12 col-md-6 col-lg-4 item">
                            <div className="card help-card featured">
                                <i className="fa-regular fa-chess-queen"></i>
                                <a href='/marketing-strategy' title='Marketing Strategy'>
                                    <h4>Marketing Strategy</h4>
                                </a>
                                <p>We analyze your strategy, define your goals, and implement the best marketing
                                    tactics</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 item">
                            <div className="card help-card">
                                <i className="fa-regular fa-lightbulb"></i>
                                <a href='/influencer-marketing' title='Influencer Marketing'>
                                    <h4>Influencer Marketing</h4>
                                </a>
                                <p>Collaborating with top influencers, we connect your brand with the right
                                    audience.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 item">
                            <div className="card help-card">
                                <i className="fa-solid fa-code"></i>
                                <a href='/web-development' title='Web Development'>
                                    <h4>Web Development</h4>
                                </a>
                                <p>We design modern, user-friendly websites, tailored to meet your specific needs.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 item">
                            <div className="card help-card">
                                <i className="fa-solid fa-film"></i>
                                <a href='/video-promo' title='Video Promo'>
                                    <h4>Video Promo</h4>
                                </a>
                                <p>We create engaging promotional videos that capture your brand’s essence and
                                    vision.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 item">
                            <div className="card help-card">
                                <i className="fa-brands fa-searchengin"></i>
                                <a href='/seo-sem' title='SEO & SEM'>
                                    <h4>SEO & SEM</h4>
                                </a>
                                <p>Optimizing search engine visibility, we drive targeted traffic to your website
                                    efficiently.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 item">
                            <div className="card help-card">
                                <i className="fa-solid fa-compass-drafting"></i>
                                <a href='/graphic-design' title='Graphic Design'>
                                    <h4>Graphic Design</h4>
                                </a>
                                <p>Our team crafts visually stunning designs that effectively communicate your brand’s
                                    message.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <ContactSection/>
        </>
    );
};

export default Services;
