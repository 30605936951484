import { UploadFile } from "./UploadFile";

export class KeyFilePair {
    key!: string;
    files!: UploadFile[];

    public constructor(key: string, files: UploadFile[]) {
       this.key = key;
       this.files = files;
    }
}