import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

// import Mobile from "../layouts/Mobile";

import { useTranslation } from 'react-i18next';
import ContactSection from '../components/ContactSection';


 

const HomePage: React.FC = () => {

    const { t } = useTranslation(); // useTranslation hook'unu kullanarak 't' fonksiyonuna eriş
    return (
        <>
            <section id='home' className='home'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='welcome-screen'>
                                <h1 className=' animate__animated animate__fadeIn'>{t('slideH1')}
                                </h1>
                                <p className='mb-5  animate__animated animate__fadeIn'>{t('slideParagraph')}

                                </p>
                                <a className='button-default  animate__animated animate__fadeInUp' href='/randevu'
                                   rel='noreferrer' title= {t('makeAnAppointment')}>
                                   {t('makeAnAppointment')}
                                </a>
                           
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='main-blocks'>
                <div className='container'>
                    <div className='row g-0 row-shadow'>
                        <div className='col-lg-4'>
                            <div className='block-syle-01   animate__animated animate__fadeIn'>

                                <div className='block-icon'>
                                <i className="bi bi-file-earmark-zip"></i>
                                </div>
                                <h2>{t('requiredDocuments')}
                                </h2>
                                <p>{t('requiredDocumentsDescription')}

                                </p>

                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='block-syle-01 gray-box   animate__animated animate__fadeIn'>

                                <div className='block-icon'>
                                <i className="bi bi-send-check"></i>
                                </div>
                                <h2>{t('preliminaryApplication')}
                                </h2>
                                <p>{t('preliminaryApplicationDescription')}

                                </p>

                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='block-syle-01   animate__animated animate__fadeIn'>

                                <div className='block-icon'>
                                <i className="bi bi-check2-circle"></i>
                                </div>
                                <h2>{t('approvalProcess')}
                                </h2>
                                <p>{t('approvalProcessDescription')}


                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='content-style-02 pt-5 mt-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-5'>
                            <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
                                <h2>{t('WhatIsBeVisa')}</h2>
                            </ScrollAnimation>
                        </div>
                        <div className='col-lg-7'>
                            <ScrollAnimation animateIn="fadeInRight" animateOnce={true}>
                                <p>
                                {t('WhatIsBeVisaText')}
                                </p>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </section>
            <section id='features' className='key-features-section py-6'>
                <div className='container'>
                    <div className="row justify-content-center items">
                        <div className='col-lg-12'>
                            <div className='feature-column mb-5'>
                                <ScrollAnimation animateIn="fadeInDown" animateOnce={true}>
                                    <p className='sub-title'>{t('servicesUppercase')}</p>
                                    <h2>{t('visaTypes')}</h2>
                                </ScrollAnimation>

                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4 item'>
                            <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>

                                <div className="card help-card featured">
                                <i className="bi bi-buildings"></i>
                                    <a href='/ticari-vize' title={t('businessVisa')}>
                                        <h4>{t('businessVisa')}</h4>
                                    </a>
                                    <p>
                                    {t('businessVisaText')}
                                    </p>
                                    <div className='box-number'>
                                        01
                                    </div>
                                </div>
                            </ScrollAnimation>
                        </div>

                        <div className='col-12 col-md-6 col-lg-4 item'>
                            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                                <div className="card help-card">
                                <i className="bi bi-camera"></i>
                                    <a href='/turistik-vize' title={t('touristVisa')}>
                                        <h4>{t('touristVisa')}</h4>
                                    </a>
                                    <p>
                                    {t('touristVisaText')}

                                    </p>
                                    <div className='box-number'>
                                        02
                                    </div>
                                </div>
                            </ScrollAnimation>
                        </div>

                        <div className='col-12 col-md-6 col-lg-4 item'>
                            <ScrollAnimation animateIn="fadeInRight" animateOnce={true}>
                                <div className="card help-card">
                                <i className="bi bi-people"></i>
                                    <a href='/aile-vizesi' title={t('familyVisa')}>
                                        <h4>{t('familyVisa')}</h4>
                                    </a>
                                    <p>
                                    {t('familyVisaText')}
                                    </p>
                                    <div className='box-number'>
                                        03
                                    </div>
                                </div>
                            </ScrollAnimation>
                        </div>


                        <div className='col-12 col-md-6 col-lg-4 item'>
                            <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
                                <div className="card help-card">
                                <i className="bi bi-briefcase"></i>
                                    <a href='/calisma-vizesi' title={t('workVisa')}>
                                        <h4>{t('workVisa')}
                                        </h4>
                                    </a>
                                    <p>
                                    {t('workVisaText')}
                                    </p>
                                    <div className='box-number'>
                                        04
                                    </div>
                                </div>
                            </ScrollAnimation>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 item">
                            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                                <div className="card help-card">
                                <i className="bi bi-airplane"></i>
                                    <a href='/transit-vize' title={t('transitVisa')}>
                                        <h4>{t('transitVisa')}</h4>
                                    </a>
                                    <p>
                                    {t('transitVisaText')}
                                    </p>
                                    <div className='box-number'>
                                        05
                                    </div>
                                </div>
                            </ScrollAnimation>
                        </div>

                        <div className="col-12 col-md-6 col-lg-4 item">
                            <ScrollAnimation animateIn="fadeInRight" animateOnce={true}>
                                <div className="card help-card">
                                <i className="bi bi-hourglass"></i>
                                    <a href='/about-us' title={t('processTracking')}>
                                        <h4>{t('processTracking')}</h4>
                                    </a>
                                    <p>
                                    {t('processTrackingText')}
                                    </p>
                                    <div className='box-number'>
                                        06
                                    </div>
                                </div>
                            </ScrollAnimation>
                        </div>


                    </div>
                </div>
            </section>
            <ContactSection />
        </>
    )
};

export default HomePage;
