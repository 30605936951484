import React from 'react';
import Form from '../../components/Form';
import { useTranslation } from 'react-i18next';
import ContactSection from '../../components/ContactSection';
import { Helmet } from 'react-helmet';

interface CommercialVisaProps {}

const CommercialVisa: React.FC<CommercialVisaProps> = (props) => {
    const { t } = useTranslation(); // useTranslation hook'unu kullanarak 't' fonksiyonuna eriş
    return (
        
        <>
            <Helmet>
                <title>{t('businessVisa')}</title>
                <meta name="keywords" content="Cezayir Ticari Vize" />
            </Helmet>
            <section className='breadbrumb-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 breadcrumb-container'>
                            <h1>{t('businessVisa')}</h1>
                            <ul className="breadcrumbs">
                                <li><a href="/">{t('home')}</a></li>
                                <li>{t('businessVisa')}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className='content-style-01 py-5 my-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h5>{t('applicationProcess')}</h5>
                            <h2>{t('whatIsABusinessVisa')}</h2>
                            <p>{t('bussinessTexts.p1')}</p>

<h3 className='mb-3'>{t('bussinessTexts.h3')}</h3>
<ul className='mb-4'>
    <li>
    {t('bussinessTexts.li1')}
    </li>
    <li>
    {t('bussinessTexts.li2')}
    </li>
    <li>
    {t('bussinessTexts.li3')}
    </li>

    <li>
    {t('bussinessTexts.li4')}
    </li>
    <li>
    {t('bussinessTexts.li5')}
    </li>
    <li>
    {t('bussinessTexts.li6')}
    </li>
    <li>
    {t('bussinessTexts.li7')}
    </li>
    <li>
    {t('bussinessTexts.li8')}
    </li>
 </ul>
<h3 className='mb-3'>
{t('bussinessTexts.h3-2')}
</h3>
<p>
{t('bussinessTexts.p2')}
 </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='form-section mb-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='content-style-01'>
                            <h5>{t('haveAQuestion')}</h5>
                            <h2>{t('writeToUs')}</h2>
                            </div>
                            <Form />
                        </div>
                    </div>
                </div>
            </section>

            <ContactSection />
        </>
    );
}

export default CommercialVisa;