export class UploadFile {
    name!: string;
    base64!: string;    
    extension!: string;

    public constructor(name: string, base64: string, extension: string) {
        this.name = name;
        this.base64 = base64;
        this.extension = extension;
    }
}