import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const ContactSection: React.FC = () => {
    const { t } = useTranslation(); // useTranslation hook'unu kullanarak 't' fonksiyonuna eriş
    const navigate = useNavigate()
    
    return (
        <section className='contact-section'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <p>{t('algeriaVisa')}</p>
                        <h2 className='mb-5'>
                            <span>{t('application')}</span> {t('form')}
                        </h2>
                        <button className='button-default' title={t('appointmentForm')} onClick={() => {
                            navigate('/randevu')
                        }}>{t('appointmentForm')}</button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactSection;
