import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone';
import Tippy from '@tippyjs/react';
import { FamilyVisaInformation } from '../../models/FamilyVisaInformation';
import moment from 'moment';
import FileUploader from '../FileUploader';

interface FamilyVisaFormProps {
    data: FamilyVisaInformation;
    onChange: (familyVisaInformation: FamilyVisaInformation) => void;
}

const FamilyVisaForm: React.FC<FamilyVisaFormProps> = ({ data, onChange }) => {
    const { t } = useTranslation();

    const handleInputChange = (field: keyof FamilyVisaInformation) => (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const value = event.target.type === 'number' ? parseFloat(event.target.value) : event.target.value;
        onChange(new FamilyVisaInformation({
            ...data,
            [field]: value
        }));
    };

    const handleDrop = (field: keyof FamilyVisaInformation) => (acceptedFiles: File[]) => {
        onChange(new FamilyVisaInformation({
            ...data,
            [field]: acceptedFiles
        }));
    };

    return (
        <div className='form-box mb-4'>
            <h2><span className='step'></span>{t('visaFormPage.familyVisa')}</h2>
            <div className='row'>
                <div className='col-lg-4 mb-3'>
                    <label htmlFor="visaSupporterClosenessDegree" className="form-label">{t('visaFormPage.label56')}
                        <Tippy content={t('tooltip.visaSupporterClosenessDegree')} placement="top">
                            <button className='form-tooltip'><i className="bi bi-question-circle-fill"></i></button>
                        </Tippy>
                    </label>
                    <input type="text" className="form-control" id="visaSupporterClosenessDegree" placeholder="" value={data.visaSupporterClosenessDegree} onChange={handleInputChange('visaSupporterClosenessDegree')} />
                </div>
                <div className='col-lg-4 mb-3'>
                    <label htmlFor="visaSupporterFullName" className="form-label">{t('visaFormPage.label57')}</label>
                    <input type="text" className="form-control" id="visaSupporterFullName" placeholder="" value={data.visaSupporterFullName} onChange={handleInputChange('visaSupporterFullName')} />
                </div>
                <div className='col-lg-4 mb-3'>
                    <label htmlFor="supporterAlgIdNumber" className="form-label">{t('visaFormPage.label58')}
                    </label>
                    <input type="text" className="form-control" id="supporterAlgIdNumber" placeholder="" value={data.supporterAlgIdNumber} onChange={handleInputChange('supporterAlgIdNumber')} />
                </div>
                <div className='col-lg-3 mb-3'>
                    <label htmlFor="supporterNationality" className="form-label">{t('visaFormPage.label60')}</label>
                    <input type="text" className="form-control" id="supporterNationality" placeholder="" value={data.supporterNationality} onChange={handleInputChange('supporterNationality')} />
                </div>
                <div className='col-lg-3 mb-3'>
                    <label htmlFor="supporterBirthDate" className="form-label">{t('visaFormPage.label59')}</label>
                    <input type="date" className="form-control" id="supporterBirthDate" placeholder="" value={moment(data.supporterBirthDate).format('YYYY-MM-DD')} onChange={handleInputChange('supporterBirthDate')} />
                </div>

                <div className='col-lg-3 mb-3'>
                    <label htmlFor="familyVisaDuration" className="form-label">{t('visaFormPage.label61')}</label>
                    <select id='familyVisaDuration' className="form-select" aria-label="Default select familyVisaDuration" value={data.familyVisaDuration} onChange={handleInputChange('familyVisaDuration')}>
                        <option selected>{t('visaFormPage.select')}</option>
                        <option value="1">{t('visaFormPage.1Month')}</option>
                        <option value="2">{t('visaFormPage.3Months')}</option>
                    </select>
                </div>
                <div className='col-lg-3 mb-3'>
                    <label htmlFor="visaStartDate" className="form-label">{t('visaFormPage.label62')}
                        <Tippy content={t('tooltip.visaStartDate')} placement="top">
                            <button className='form-tooltip'><i className="bi bi-question-circle-fill"></i></button>
                        </Tippy>
                    </label>
                    <input type="date" className="form-control" id="visaStartDate" placeholder="" value={moment(data.visaStartDate).format('YYYY-MM-DD')} onChange={handleInputChange('visaStartDate')} />
                </div>
                <div className='col-lg-12 mb-3'>
                    <label htmlFor="marriageCertificateCopy" className="form-label">{t('visaFormPage.label63')}
                        <Tippy content={t('tooltip.marriageCertificateCopy')} placement="top">
                            <button className='form-tooltip'><i className="bi bi-question-circle-fill"></i></button>
                        </Tippy>
                    </label>
                    <FileUploader onChange={handleDrop('marriageCertificateCopy')} label={t('visaFormPage.fileUploadTitle')} />
                </div>
                <div className='col-lg-6 mb-3'>
                    <label htmlFor="civilRecords" className="form-label">{t('visaFormPage.label64')}
                        <Tippy content={t('tooltip.civilRecords')} placement="top">
                            <button className='form-tooltip'><i className="bi bi-question-circle-fill"></i></button>
                        </Tippy>
                    </label>
                    <FileUploader onChange={handleDrop('civilRecords')} label={t('visaFormPage.fileUploadTitle')} />
                </div>
                <div className='col-lg-6 mb-3'>
                    <label htmlFor="birthCertificate" className="form-label">{t('visaFormPage.label65')}
                        <Tippy content={t('tooltip.birthCertificate')} placement="top">
                            <button className='form-tooltip'><i className="bi bi-question-circle-fill"></i></button>
                        </Tippy>
                    </label>
                    <FileUploader onChange={handleDrop('birthCertificate')} label={t('visaFormPage.fileUploadTitle')} />
                </div>
                <div className='col-lg-6 mb-3'>
                    <label htmlFor="supporterPassportCopy" className="form-label">{t('visaFormPage.label66')}</label>
                    <FileUploader onChange={handleDrop('supporterPassportCopy')} label={t('visaFormPage.fileUploadTitle')} />
                </div>
                <div className='col-lg-6 mb-3'>
                    <label htmlFor="supporterIdCopy" className="form-label">{t('visaFormPage.label67')}</label>
                    <FileUploader onChange={handleDrop('supporterIdCopy')} label={t('visaFormPage.fileUploadTitle')} />
                </div>
                <div className='col-lg-6 mb-3'>
                    <label htmlFor="familyInvitation" className="form-label">{t('visaFormPage.label68')}
                        <Tippy content={t('tooltip.familyInvitation')} placement="top">
                            <button className='form-tooltip'><i className="bi bi-question-circle-fill"></i></button>
                        </Tippy>
                    </label>
                    <FileUploader onChange={handleDrop('familyInvitation')} label={t('visaFormPage.fileUploadTitle')} />
                </div>
                <div className='col-lg-6 mb-3'>
                    <label htmlFor="kinshipCertificate" className="form-label">{t('visaFormPage.label69')}
                        <Tippy content={t('tooltip.kinshipCertificate')} placement="top">
                            <button className='form-tooltip'><i className="bi bi-question-circle-fill"></i></button>
                        </Tippy>
                    </label>
                    <FileUploader onChange={handleDrop('kinshipCertificate')} label={t('visaFormPage.fileUploadTitle')} />
                </div>
            </div>
        </div>
    );
};

export default FamilyVisaForm;