import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import ContactSection from "../components/ContactSection";
// import ContactSection from '../components/contact-section';

const FAQs: React.FC = () => {
    const { t } = useTranslation(); // useTranslation hook'unu kullanarak 't' fonksiyonuna eriş
    return (
        <>
            <Helmet>
                <title>Be Partners İletişim</title>
                <meta name="keywords" content="Be Partners Sık Sorulanlar" />
            </Helmet>
            <section className='breadbrumb-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 breadcrumb-container'>
                            <h1>{t('faqs')}</h1>
                            <ul className="breadcrumbs">
                                <li><a href="/static">{t('home')}</a></li>
                                <li>{t('faqs')}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section id='faqs' className='faqs-section py-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className="accordion accordion-flush accordion-faqs" id="accordion-faqs">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-1" aria-expanded="true" aria-controls="flush-collapseOne">
                                            {t('faq1.question')}

                                        </button>
                                    </h2>
                                    <div id="flush-1" className="accordion-collapse collapse show" data-bs-parent="#accordion-faqs">
                                        <div className="accordion-body">
                                            {t('faq1.answer')}
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-2" aria-expanded="false" aria-controls="flush-collapseTwo">
                                            {t('faq2.question')}
                                        </button>
                                    </h2>
                                    <div id="flush-2" className="accordion-collapse collapse" data-bs-parent="#accordion-faqs">
                                        <div className="accordion-body">
                                            {t('faq2.answer')}
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-3" aria-expanded="false" aria-controls="flush-collapseThree">
                                            {t('faq3.question')}
                                        </button>
                                    </h2>
                                    <div id="flush-3" className="accordion-collapse collapse" data-bs-parent="#accordion-faqs">
                                        <div className="accordion-body">
                                            {t('faq3.answer')}
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-4" aria-expanded="false" aria-controls="flush-collapseFour">
                                            {t('faq4.question')}
                                        </button>
                                    </h2>
                                    <div id="flush-4" className="accordion-collapse collapse" data-bs-parent="#accordion-faqs">
                                        <div className="accordion-body">
                                            {t('faq4.answer')}                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-5" aria-expanded="false" aria-controls="flush-collapseFive">
                                            {t('faq5.question')}
                                        </button>
                                    </h2>
                                    <div id="flush-5" className="accordion-collapse collapse" data-bs-parent="#accordion-faqs">
                                        <div className="accordion-body">
                                            {t('faq5.answer')}                                        </div>
                                    </div>
                                </div>


                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-6" aria-expanded="false" aria-controls="flush-collapseFive">
                                            {t('faq6.question')}
                                        </button>
                                    </h2>
                                    <div id="flush-6" className="accordion-collapse collapse" data-bs-parent="#accordion-faqs">
                                        <div className="accordion-body">
                                            {t('faq6.answer')}
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-7" aria-expanded="false" aria-controls="flush-collapseFive">
                                            {t('faq7.question')}
                                        </button>
                                    </h2>
                                    <div id="flush-7" className="accordion-collapse collapse" data-bs-parent="#accordion-faqs">
                                        <div className="accordion-body">
                                            {t('faq8.answer')}
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-8" aria-expanded="false" aria-controls="flush-collapseFive">
                                            {t('faq8.question')}
                                        </button>
                                    </h2>
                                    <div id="flush-8" className="accordion-collapse collapse" data-bs-parent="#accordion-faqs">
                                        <div className="accordion-body">
                                            {t('faq8.answer')}
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-9" aria-expanded="false" aria-controls="flush-collapseFive">
                                            {t('faq9.question')}
                                        </button>
                                    </h2>
                                    <div id="flush-9" className="accordion-collapse collapse" data-bs-parent="#accordion-faqs">
                                        <div className="accordion-body">
                                            {t('faq9.answer')}
                                        </div>
                                    </div>
                                </div>


                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-10" aria-expanded="false" aria-controls="flush-collapseFive">
                                            {t('faq10.question')}
                                        </button>
                                    </h2>
                                    <div id="flush-10" className="accordion-collapse collapse" data-bs-parent="#accordion-faqs">
                                        <div className="accordion-body">
                                            {t('faq10.answer')}
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-11" aria-expanded="false" aria-controls="flush-collapseFive">
                                            {t('faq11.question')}
                                        </button>
                                    </h2>
                                    <div id="flush-11" className="accordion-collapse collapse" data-bs-parent="#accordion-faqs">
                                        <div className="accordion-body">
                                            {t('faq11.answer')}
                                        </div>
                                    </div>
                                </div>


                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-12" aria-expanded="false" aria-controls="flush-collapseFive">
                                            {t('faq12.question')}
                                        </button>
                                    </h2>
                                    <div id="flush-12" className="accordion-collapse collapse" data-bs-parent="#accordion-faqs">
                                        <div className="accordion-body">
                                        {t('faq12.answer')}
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-13" aria-expanded="false" aria-controls="flush-collapseFive">
                                            {t('faq13.question')}
                                        </button>
                                    </h2>
                                    <div id="flush-13" className="accordion-collapse collapse" data-bs-parent="#accordion-faqs">
                                        <div className="accordion-body">
                                        {t('faq13.answer')}
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-14" aria-expanded="false" aria-controls="flush-collapseFive">
                                            {t('faq14.question')}

                                        </button>
                                    </h2>
                                    <div id="flush-14" className="accordion-collapse collapse" data-bs-parent="#accordion-faqs">
                                        <div className="accordion-body">
                                        {t('faq14.answer')}
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-15" aria-expanded="false" aria-controls="flush-collapseFive">
                                            {t('faq15.question')}


                                        </button>
                                    </h2>
                                    <div id="flush-15" className="accordion-collapse collapse" data-bs-parent="#accordion-faqs">
                                        <div className="accordion-body">
                                        {t('faq15.answer')}
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-16" aria-expanded="false" aria-controls="flush-collapseFive">
                                            {t('faq16.question')}
                                        </button>
                                    </h2>
                                    <div id="flush-16" className="accordion-collapse collapse" data-bs-parent="#accordion-faqs">
                                        <div className="accordion-body">
                                        {t('faq16.answer')}
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-17" aria-expanded="false" aria-controls="flush-collapseFive">
                                            {t('faq17.question')}

                                        </button>
                                    </h2>
                                    <div id="flush-17" className="accordion-collapse collapse" data-bs-parent="#accordion-faqs">
                                        <div className="accordion-body">
                                        {t('faq17.answer')}
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-18" aria-expanded="false" aria-controls="flush-collapseFive">
                                            {t('faq18.question')}


                                        </button>
                                    </h2>
                                    <div id="flush-18" className="accordion-collapse collapse" data-bs-parent="#accordion-faqs">
                                        <div className="accordion-body">
                                            {t('faq18.answer')}
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-19" aria-expanded="false" aria-controls="flush-collapseFive">
                                            {t('faq19.question')}
                                        </button>
                                    </h2>
                                    <div id="flush-19" className="accordion-collapse collapse" data-bs-parent="#accordion-faqs">
                                        <div className="accordion-body">
                                        {t('faq19.answer')}
                                            </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-20" aria-expanded="false" aria-controls="flush-collapseFive">
                                            {t('faq20.question')}
                                        </button>
                                    </h2>
                                    <div id="flush-20" className="accordion-collapse collapse" data-bs-parent="#accordion-faqs">
                                        <div className="accordion-body">
                                        {t('faq20.answer')}
                                        </div>
                                    </div>

                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-21" aria-expanded="false" aria-controls="flush-collapseFive">
                                            {t('faq21.question')}

                                        </button>
                                    </h2>
                                    <div id="flush-21" className="accordion-collapse collapse" data-bs-parent="#accordion-faqs">
                                        <div className="accordion-body">
                                        {t('faq21.answer')}
                                        </div>
                                    </div>

                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-22" aria-expanded="false" aria-controls="flush-collapseFive">
                                            {t('faq22.question')}


                                        </button>
                                    </h2>
                                    <div id="flush-22" className="accordion-collapse collapse" data-bs-parent="#accordion-faqs">
                                        <div className="accordion-body">
                                        {t('faq22.answer')}
                                             </div>
                                    </div>

                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-23" aria-expanded="false" aria-controls="flush-collapseFive">
                                            {t('faq23.question')}
                                        </button>
                                    </h2>
                                    <div id="flush-23" className="accordion-collapse collapse" data-bs-parent="#accordion-faqs">
                                        <div className="accordion-body">
                                        {t('faq23.answer')}                                             </div>

                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-24" aria-expanded="false" aria-controls="flush-collapseFive">
                                            {t('faq24.question')}
                                        </button>
                                    </h2>
                                    <div id="flush-24" className="accordion-collapse collapse" data-bs-parent="#accordion-faqs">
                                        <div className="accordion-body">
                                            {t('faq24.answer')}
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <ContactSection />
        </>
    );
};

export default FAQs;
