import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'tr',  // Varsayılan dil
    lng: localStorage.getItem('i18nextLng') || 'tr', // Başlangıçta saklanan dili ayarla
    debug: true,
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['cookie', 'localStorage'],
    },
    interpolation: {
      escapeValue: false, // React zaten xss koruması sağlıyor
    },
  });

export default i18n;
