import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone';
import Tippy from '@tippyjs/react';
import _ from 'lodash';
import { TouristVisaInformation } from '../../models/TouristVisaInformation';
import moment from 'moment';
import FileUploader from '../FileUploader';

interface TouristVisaFormProps {
    data: TouristVisaInformation;
    onChange: (touristVisaInformation: TouristVisaInformation) => void;
}

const TouristVisaForm: React.FC<TouristVisaFormProps> = ({ data, onChange }) => {
    const { t } = useTranslation();
    
    const handleInputChange = (field: keyof TouristVisaInformation) => (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const value = event.target.type === 'number' ? parseFloat(event.target.value) : event.target.value;
        onChange(new TouristVisaInformation({
            ...data,
            [field]: value
        }));
    };

    const handleDrop = (field: keyof TouristVisaInformation) => (acceptedFiles: File[]) => {
        onChange(new TouristVisaInformation({
            ...data,
            [field]: acceptedFiles
        }));
    };

    return (
        <div className='form-box mb-4'>
            <h2><span className='step'></span>{t('visaFormPage.touristVisa')}</h2>
            <div className='row'>
                <div className='col-lg-4 mb-3'>
                    <label htmlFor="roundTripTicket" className="form-label">{t('visaFormPage.label41')}
                        <Tippy content={t('tooltip.roundTripTicket')} placement="top">
                            <button className='form-tooltip'><i className="bi bi-question-circle-fill"></i></button>
                        </Tippy>
                    </label>
                    <input type="text" className="form-control" id="roundTripTicket" placeholder="" value={data.roundTripTicket} onChange={handleInputChange('roundTripTicket')} />
                </div>
                <div className='col-lg-4 mb-3'>
                    <label htmlFor="travelHealthInsurance" className="form-label">{t('visaFormPage.label42')}
                        <Tippy content={t('tooltip.travelHealthInsurance')} placement="top">
                            <button className='form-tooltip'><i className="bi bi-question-circle-fill"></i></button>
                        </Tippy>
                    </label>
                    <input type="text" className="form-control" id="travelHealthInsurance" placeholder="" value={data.travelHealthInsurance} onChange={handleInputChange('travelHealthInsurance')} />
                </div>
                <div className='col-lg-4 mb-3'>
                    <label htmlFor="stayAddress" className="form-label">{t('visaFormPage.label43')}</label>
                    <input type="date" className="form-control" id="reasonForVisit" placeholder="" value={data.reasonForVisit} onChange={handleInputChange('reasonForVisit')} />
                </div>
                <div className='col-lg-4 mb-3'>
                    <label htmlFor="invitationStatus" className="form-label">{t('visaFormPage.label44')}</label>
                    <select id='invitationStatus' className="form-select" aria-label="Default select invitationStatus" value={data.invitationStatus} onChange={handleInputChange('invitationStatus')}>
                        <option selected>{t('visaFormPage.select')}</option>
                        <option value="1">{t('visaFormPage.available')}</option>
                        <option value="2">{t('visaFormPage.notAvailable')}</option>
                    </select>
                </div>
                <div className='col-lg-4 mb-3'>
                    <label htmlFor="inviterIsFullName" className="form-label">{t('visaFormPage.label45')}</label>
                    <input type="text" className="form-control" id="inviterIsFullName" placeholder="" value={data.inviterIsFullName} onChange={handleInputChange('inviterIsFullName')} />
                </div>
                <div className='col-lg-4 mb-3'>
                    <label htmlFor="inviterIdNumber" className="form-label">{t('visaFormPage.label46')}</label>
                    <input type="text" className="form-control" id="inviterIdNumber" placeholder="" value={data.inviterIdNumber} onChange={handleInputChange('inviterIdNumber')} />
                </div>
                <div className='col-lg-4 mb-3'>
                    <label htmlFor="hotelReservation" className="form-label">{t('visaFormPage.label47')}</label>
                    <div className='row'>
                        <div className='col-6'>
                            <input type="date" className="form-control" id="hotelReservationStartDate" placeholder="" value={moment(data.hotelReservationStartDate).format('YYYY-MM-DD')} onChange={handleInputChange('hotelReservationStartDate')} />
                        </div>
                        <div className='col-6'>
                            <input type="date" className="form-control" id="hotelReservationEndDate" placeholder="" value={moment(data.hotelReservationEndDate).format('YYYY-MM-DD')} onChange={handleInputChange('hotelReservationEndDate')} />
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 mb-3'>
                    <label htmlFor="hotelInfo" className="form-label">{t('visaFormPage.label48')}</label>
                    <input type="text" className="form-control" id="hotelInfo" placeholder="" value={data.hotelInfo} onChange={handleInputChange('hotelInfo')} />
                </div>
                <div className='col-lg-4 mb-3'>
                    <label htmlFor="travelVisaDuration" className="form-label">{t('visaFormPage.label49')}</label>
                    <select id='travelVisaDuration' className="form-select" aria-label="Default select travelVisaDuration" value={data.travelVisaDuration} onChange={handleInputChange('travelVisaDuration')}>
                        <option selected>{t('visaFormPage.select')}</option>
                        <option value="1">{t('visaFormPage.1Month')}</option>
                    </select>
                </div>
                <div className='col-lg-6 mb-3'>
                    <label htmlFor="proofOfEmploymentOrStudentCer" className="form-label">{t('visaFormPage.label50')}
                        <Tippy content={t('tooltip.proofOfEmploymentOrStudentCer')} placement="top">
                            <button className='form-tooltip'><i className="bi bi-question-circle-fill"></i></button>
                        </Tippy>
                    </label>
                    <FileUploader onChange={handleDrop('proofOfEmploymentOrStudentCer')} label={t('visaFormPage.fileUploadTitle')} />
                </div>
                <div className='col-lg-6 mb-3'>
                    <label htmlFor="proofOfIncome" className="form-label">{t('visaFormPage.label51')}
                        <Tippy content={t('tooltip.proofOfIncome')} placement="top">
                            <button className='form-tooltip'><i className="bi bi-question-circle-fill"></i></button>
                        </Tippy>
                    </label>
                    <FileUploader onChange={handleDrop('proofOfIncome')} label={t('visaFormPage.fileUploadTitle')} />
                </div>
                <div className='col-lg-6 mb-3'>
                    <label htmlFor="hotelReservationOrInvitationCopy" className="form-label">{t('visaFormPage.label52')}</label>
                    <FileUploader onChange={handleDrop('hotelReservationOrInvitationCopy')} label={t('visaFormPage.fileUploadTitle')} />
                </div>
                <div className='col-lg-6 mb-3'>
                    <label htmlFor="inviterIdCopy" className="form-label">{t('visaFormPage.label53')}</label>
                    <FileUploader onChange={handleDrop('inviterIdCopy')} label={t('visaFormPage.fileUploadTitle')} />
                </div>
                {/* <div className='col-lg-6 mb-3'>
                    <label htmlFor="hotelReservationOrInvitationCopy" className="form-label">{t('visaFormPage.label54')}</label>
                    <FileUploader onChange={handleDrop('inviterIDCopy')} label={t('visaFormPage.fileUploadTitle')} />
                </div> */}
                <div className='col-lg-6 mb-3'>
                    <label htmlFor="expiryDate" className="form-label">{t('visaFormPage.label55')}</label>
                    <FileUploader onChange={handleDrop('expiryDate')} label={t('visaFormPage.fileUploadTitle')} />
                </div>
            </div>
        </div>
    );
};

export default TouristVisaForm;