import React, { useEffect, useState } from 'react';
import banner2 from '../assets/banner-2.webp';
import { AuthenticationService } from '../services/AuthenticationService';
import { useSessionStore } from '../stores/sessionStore';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Login: React.FC = () => {

    const navigate = useNavigate();
    const authService = new AuthenticationService();

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const sessionStore = useSessionStore();


    useEffect(() => {
        if (sessionStore.hasSession()) {
            navigate('/basvurular')
        }
    }, [])

    const { t } = useTranslation(); // useTranslation hook'unu kullanarak 't' fonksiyonuna eriş
    return (
        <>
            <section className='breadbrumb-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 breadcrumb-container'>
                            <h1>{t('userLogin')}</h1>
                            <ul className="breadcrumbs">
                                <li><a href="/">{t('home')}</a></li>
                                <li>{t('Login')}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className='content-style-01 py-6'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-7 mx-auto'>
                            <div className='form-box mb-4'>
                                <h2>{t('userLogin')}</h2>
                                <form className='user-form'>
                                    <div className='col-lg-12 mb-3'>
                                        <label htmlFor="email" className="form-label">{t('email')}</label>
                                        <input type="email" className="form-control" id="email" placeholder=""
                                            value={email}
                                            onChange={(event) => {
                                                setEmail(event?.target?.value)
                                            }}
                                        ></input>
                                    </div>
                                    <div className='col-lg-12 mb-3'>
                                        <label htmlFor="password" className="form-label">{t('password')}</label>
                                        <input type="password" className="form-control" id="password" placeholder=""
                                            value={password}
                                            onChange={(event) => {
                                                setPassword(event?.target?.value)
                                            }}
                                        ></input>
                                    </div>
                                    {
                                        error &&
                                        <div className={'error-messages'}>
                                            <span className={'danger'}>
                                                <i className="bi bi-exclamation-diamond-fill me-1"></i>{error}
                                            </span>
                                        </div>
                                    }
                                    <a className='button-default mt-4 w-100'  title={t('loginButton')}
                                        onClick={() => {
                                            if (email.trim().length <= 0 || password.trim().length <= 0) {
                                                toast.warning('email or password can\'t be empty');
                                                return
                                            }
                                            authService
                                                .login(email.trim(), password.trim())
                                                .then(response => {
                                                    if (response.access_token) {
                                                        setError('')
                                                        sessionStore.login(response);
                                                        setTimeout(() => {
                                                            navigate('/randevu')
                                                        }, 200)
                                                    }
                                                    if (response.error) {
                                                        setError(response.error)
                                                    }
                                                })
                                        }}
                                    >
                                       {t('login')}
                                    </a>

                                    <div className='d-flex justify-content-between'>
                                    <a className='button-link mt-3 d-block'  href='/reset-password' rel='noreferrer' title={t('forgotPassword')}>
                                        <i className="bi bi-question-circle"></i> {t('forgotPassword')}
                                    </a>

                                    <a className='button-link mt-3 d-block' href='/register' rel='noreferrer' title={t('register')}>
                                    <i className="bi bi-person-add"></i> {t('register')}
                                    </a>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className='col-lg-5 d-none'>
                            <img className='img-fluid radius' src={banner2} title='Banner1' alt='Banner1'></img>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Login;
