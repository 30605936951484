import React from 'react';
import Form from '../../components/Form';
import { useTranslation } from 'react-i18next';
import ContactSection from '../../components/ContactSection';
import { Helmet } from 'react-helmet';

interface FamilyVisaProps { }

const FamilyVisa: React.FC<FamilyVisaProps> = (props) => {
        const { t } = useTranslation(); // useTranslation hook'unu kullanarak 't' fonksiyonuna eriş
    return (
        <>
            <Helmet>
                <title>{t('familyVisa')}</title>
                <meta name="keywords" content="Cezayir Aile Vizesi" />
            </Helmet>
            <section className='breadbrumb-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 breadcrumb-container'>
                            <h1>{t('familyVisa')}</h1>
                            <ul className="breadcrumbs">
                                <li><a href="/">{t('home')}</a></li>
                                <li>{t('familyVisa')}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className='content-style-01 py-5 my-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h5>{t('applicationProcess')}</h5>
                            <h2>{t('whatIsAFamilyVisa')}</h2>
                        <p>
                        {t('familyTexts.p1')}                             
                        </p>
                            <p>
                            {t('familyTexts.p2')}  
</p>
<h3 className='mb-3'>
{t('familyTexts.h3')} 
</h3>

<h4 className='mb-3'>
{t('familyTexts.h4')} 
</h4>
<ul className='mb-5'>
<li>
{t('familyTexts.li1')} 
</li>
<li>
{t('familyTexts.li2')} 
</li>
<li>
{t('familyTexts.li3')} 
</li>
<li>
{t('familyTexts.li4')} 
</li>
<li>
{t('familyTexts.li5')} 
</li>
<li>
{t('familyTexts.li6')} 
</li>
<li>
{t('familyTexts.li7')} 
</li>
<li>
{t('familyTexts.li8')} 
</li>
<li>
{t('familyTexts.li9')} 
</li>

</ul>
<h4 className='mb-3'>
{t('familyTexts.h4-2')} 
</h4>
<ul className='mb-5'>
<li>
{t('familyTexts.li10')} 
</li>
<li>
{t('familyTexts.li11')} 
</li>
<li>
{t('familyTexts.li12')} 
</li>
<li>
{t('familyTexts.li13')} 
</li>
<li>
{t('familyTexts.li14')} 
</li>
<li>
{t('familyTexts.li15')} 
</li>
<li>
{t('familyTexts.li16')} 
</li>
<li>
{t('familyTexts.li17')} 
</li>
</ul>
<h4 className='mb-3'>
{t('familyTexts.h4-3')} 
</h4>

<ul className='mb-5'>
<li>
{t('familyTexts.li18')} 
</li>
<li>
{t('familyTexts.li19')} 
</li>
<li>
{t('familyTexts.li20')} 
</li>
<li>
{t('familyTexts.li21')} 
</li>
<li>
{t('familyTexts.li22')} 
</li>
<li>
{t('familyTexts.li23')} 
</li>
<li>
{t('familyTexts.li24')} 
</li>
<li>
{t('familyTexts.li25')} 
</li>
</ul>

                        </div>
                    </div>
                </div>
            </section>

            <section className='form-section mb-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='content-style-01'>
                            <h5>{t('haveAQuestion')}</h5>
                            <h2>{t('writeToUs')}</h2>
                            </div>
                            <Form />
                        </div>
                    </div>
                </div>
            </section>

            <ContactSection />
        </>
    );
}

export default FamilyVisa;