import React from 'react';
import { Helmet } from 'react-helmet';
import banner2 from '../assets/banner-2.webp';
import { useNavigate } from 'react-router-dom';

const ActivationRequired: React.FC = () => {
    const navigate = useNavigate();

    return (
        <>
            <Helmet>
                <title>Activation Required</title>
                <meta name="keywords" content="Activation Required" />
            </Helmet>
            <section className='breadbrumb-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 breadcrumb-container'>
                            <h1>Activation Required</h1>
                            <ul className="breadcrumbs">
                                <li><a href="/">Home</a></li>
                                <li>Activation Required</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className='content-style-01 py-6'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-7 mx-auto'>
                            <div className='form-box mb-4'>
                                <h2>Activation Required</h2>
                                <p>
                                    An activation link has been sent to your email address. Please check your email and click on the activation link to complete your registration.
                                </p>
                                <a className='button-default mt-4 w-100' title='Go to Home'
                                    onClick={() => navigate('/')}
                                >
                                    Go to Home
                                </a>
                            </div>
                        </div>
                        <div className='col-lg-5 d-none'>
                            <img className='img-fluid radius' src={banner2} title='Banner1' alt='Banner1' />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ActivationRequired;