import React, { FC, useEffect } from 'react';
import { useSessionStore } from '../stores/sessionStore';
import { useNavigate, useLocation } from 'react-router-dom';

interface LogoutProps {}

const Logout: FC<LogoutProps> = () => {
    const sessionStore = useSessionStore();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const callbackUrl = queryParams.get('callbackUrl') || '/login';
        
        sessionStore.logout();
        navigate(callbackUrl);
    }, [navigate, location]);

    return (
        <>
        </>
    );
}

export default Logout;