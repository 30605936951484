import React from 'react';
import { useTranslation } from 'react-i18next';
import VisaForm from '../components/VisaForm/VisaForm';
// import AppointmentForm from "../components/AppointmentForm";

const Appointment: React.FC = () => {
    const { t } = useTranslation(); // useTranslation hook'unu kullanarak 't' fonksiyonuna eriş
    return (
        <>
            <section className='breadbrumb-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 breadcrumb-container'>
                            <h1>{t('appointmentForm')}</h1>
                            <ul className="breadcrumbs">
                                <li><a href="/">{t('home')}</a></li>
                                <li>{t('appointmentForm')}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className='form-section py-6'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            {/* <AppointmentForm/> */}
                            <VisaForm></VisaForm>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};

export default Appointment;
