import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher: React.FC = () => {
  const { t, i18n } = useTranslation();

  // Seçilen dile göre ikon sınıfını dinamik olarak belirle
  const getFlagIconClass = () => {
    switch (i18n.language) {
      case 'en':
        return 'fi fi-gb'; // İngiltere bayrağı
      case 'tr':
        return 'fi fi-tr'; // Türkiye bayrağı
      case 'fr':
        return 'fi fi-fr'; // Fransa bayrağı
      case 'ar':
        return 'fi fi-sa'; // Suriye bayrağı (örnek olarak)
      default:
        return 'fi fi-gb'; // Varsayılan bayrak
    }
  };

  // Dil değiştirme fonksiyonu
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  // Dil değiştiğinde body'ye 'ar' sınıfını ekleyip kaldır
  useEffect(() => {
    const body = document.body;

    if (i18n.language === 'ar') {
      body.classList.add('ar'); // Eğer dil Arapçaysa body'ye 'ar' sınıfını ekle
    } else {
      body.classList.remove('ar'); // Diğer dillerde bu sınıfı kaldır
    }

    // Cleanup işlemi, bileşen unmount olduğunda sınıfı kaldırır
    return () => {
      body.classList.remove('ar');
    };
  }, [i18n.language]); // i18n.language değiştiğinde bu etki tetiklenir

  return (
    <div className="dropdown lang-dropdown">
      <button
        className="dropdown-toggle me-3"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span className={getFlagIconClass()}></span>
      </button>
      <ul className="dropdown-menu">
        <li>
          <button className="dropdown-item" onClick={() => changeLanguage('en')}>
            <span className="fi fi-gb"></span> {t('english')}
          </button>
        </li>
        <li>
          <button className="dropdown-item" onClick={() => changeLanguage('tr')}>
            <span className="fi fi-tr me-2"></span> {t('turkish')}
          </button>
        </li>
        <li>
          <button className="dropdown-item" onClick={() => changeLanguage('fr')}>
            <span className="fi fi-fr me-2"></span> {t('french')}
          </button>
        </li>
        <li>
          <button className="dropdown-item" onClick={() => changeLanguage('ar')}>
            <span className="fi fi-sa me-2"></span> {t('arabic')}
          </button>
        </li>
      </ul>
    </div>
  );
};

export default LanguageSwitcher;
