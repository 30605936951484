import React, { ReactNode } from 'react';
import ScreenType from "./ScreenType";

const DESKTOP_MIN_WIDTH = 1180;

interface ResponsiveProps {
    children: ReactNode;
}

const Desktop = ({ children }: ResponsiveProps) => (
    <ScreenType query={{ minWidth: DESKTOP_MIN_WIDTH }}>
        {children}
    </ScreenType>
);

export default Desktop;
