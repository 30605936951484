import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone';
import Tippy from '@tippyjs/react';
import _ from 'lodash';
import { PassportInformation } from '../../models/PassportInformation';
import { AppointmentTime } from '../../models/enums/AppointmentTime';
import moment from 'moment';
import FileUploader from '../FileUploader';

interface PassportInfoFormProps {
    data: PassportInformation;
    onChange: (passportInformation: PassportInformation) => void;
}

const PassportInfoForm: React.FC<PassportInfoFormProps> = ({ data, onChange }) => {
    const { t } = useTranslation();

    const handleInputChange = (field: keyof PassportInformation) => (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const value = event.target.type === 'number' ? parseFloat(event.target.value) : event.target.value;
        onChange(new PassportInformation({
            ...data,
            [field]: value
        }));
    };

    const handleDrop = (field: keyof PassportInformation) => (acceptedFiles: File[]) => {
        onChange(new PassportInformation({
            ...data,
            [field]: acceptedFiles
        }));
    };


    return (
        <div className='form-box mb-4'>
            <h2><span className='step'></span>{t('visaFormPage.passaportInfo')}</h2>
            <div className='row'>
                <div className='col-lg-3 mb-3'>
                    <label htmlFor="passportType" className="form-label">{t('visaFormPage.label18')}
                        <Tippy content={t('tooltip.passportType')} placement="top">
                            <button className='form-tooltip'><i className="bi bi-question-circle-fill"></i></button>
                        </Tippy>
                    </label>
                    <input type="text" className="form-control" id="passportType" placeholder="" value={data.passportType} onChange={handleInputChange('passportType')} />
                </div>
                <div className='col-lg-3 mb-3'>
                    <label htmlFor="passportNumber" className="form-label">{t('visaFormPage.label19')}
                        <Tippy content={t('tooltip.passportNumber')} placement="top">
                            <button className='form-tooltip'><i className="bi bi-question-circle-fill"></i></button>
                        </Tippy>
                    </label>
                    <input type="text" className="form-control" id="passportNumber" placeholder="" value={data.passportNumber} onChange={handleInputChange('passportNumber')} />
                </div>
                <div className='col-lg-3 mb-3'>
                    <label htmlFor="passportDate" className="form-label">{t('visaFormPage.label20')}
                        <Tippy content={t('tooltip.passportDate')} placement="top">
                            <button className='form-tooltip'><i className="bi bi-question-circle-fill"></i></button>
                        </Tippy>
                    </label>
                    <input type="date" className="form-control" id="passportDate" placeholder="" value={moment(data.passportDate).format('YYYY-MM-DD')} onChange={handleInputChange('passportDate')} />
                </div>
                <div className='col-lg-3 mb-3'>
                    <label htmlFor="passportEndDate" className="form-label">{t('visaFormPage.label21')}
                        <Tippy content={t('tooltip.passportEndDate')} placement="top">
                            <button className='form-tooltip'><i className="bi bi-question-circle-fill"></i></button>
                        </Tippy>
                    </label>
                    <input type="date" className="form-control" id="passportEndDate" placeholder="" value={moment(data.passportEndDate).format('YYYY-MM-DD')} onChange={handleInputChange('passportEndDate')} />
                </div>
                <div className='col-lg-12 mb-3'>
                    <label htmlFor="passportCopy" className="form-label">{t('visaFormPage.label22')}
                        <Tippy content={t('tooltip.PassportCopy')} placement="top">
                            <button className='form-tooltip'><i className="bi bi-question-circle-fill"></i></button>
                        </Tippy>
                    </label>
                    <FileUploader onChange={handleDrop('passportCopy')} label={t('visaFormPage.fileUploadTitle')} />
                </div>
            </div>
        </div>
    );
};

export default PassportInfoForm;