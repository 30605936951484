import * as pako from 'pako';

const ZipHelper = (() => {
    return {
        encodeBase64: (decodedStringBtoA: Uint8Array): string => {
            return btoa(String.fromCharCode.apply(null, Array.from(decodedStringBtoA)));
        },
        decodeBase64: (encodedStringAtoB: string): Uint8Array => {
            return new Uint8Array(Array.from(atob(encodedStringAtoB), (c) => c.charCodeAt(0)));
        },
        encodeGzip: (input: Uint8Array): Uint8Array => {
            return pako.deflate(input);
        },
        decodeGzip: (compressed: Uint8Array): Uint8Array => {
            return pako.inflate(compressed);
        },
        stringToByteArr: (str: string): Uint8Array => {
            return new TextEncoder().encode(str);
        },
        byteArrToString: (arr: Uint8Array): string => {
            return new TextDecoder().decode(arr);
        },
        compress: (data: string): string => {
            const inputBytes = new TextEncoder().encode(data);
            const compressedBytes = pako.deflate(inputBytes, { level: 9 });

            // Chunk the compressed bytes to avoid stack overflow
            const CHUNK_SIZE = 0x8000; // 32kb
            const chunks: string[] = [];
            for (let i = 0; i < compressedBytes.length; i += CHUNK_SIZE) {
                chunks.push(String.fromCharCode.apply(null, Array.from(compressedBytes.subarray(i, i + CHUNK_SIZE))));
            }

            // Convert each chunk to a string and concatenate
            const compressedText = chunks.join('');

            return btoa(compressedText);
        },
        decompress: (response: string): string => {
            const compressedBytes = new Uint8Array(atob(response).split('').map(char => char.charCodeAt(0)));
            const decompressedBytes = pako.inflate(compressedBytes, { to: 'string' });
            return decompressedBytes;
        },
    };
})();

export default ZipHelper;