import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone';
import Tippy from '@tippyjs/react';
import _ from 'lodash';
import { BusinessVisaInformation } from '../../models/BusinessVisaInformation';
import moment from 'moment';
import FileUploader from '../FileUploader';

interface BusinessVisaFormProps {
    data: BusinessVisaInformation;
    onChange: (businessVisaInformation: BusinessVisaInformation) => void;
}

const BusinessVisaForm: React.FC<BusinessVisaFormProps> = ({ data, onChange }) => {
    const { t } = useTranslation();

    const handleInputChange = (field: keyof BusinessVisaInformation) => (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const value = event.target.type === 'number' ? parseFloat(event.target.value) : event.target.value;
        onChange(new BusinessVisaInformation({
            ...data,
            [field]: value
        }));
    };

    const handleDrop = (field: keyof BusinessVisaInformation) => (acceptedFiles: File[]) => {
        onChange(new BusinessVisaInformation({
            ...data,
            [field]: acceptedFiles
        }));
    };

    return (
        <div className='form-box mb-4'>
            <h2><span className='step'></span>{t('visaFormPage.businessVisa')}</h2>
            <div className='row'>
                <div className='col-lg-6 mb-3'>
                    <label htmlFor="businessTurkishCompanyName" className="form-label">{t('visaFormPage.label28')}
                        <Tippy content={t('tooltip.businessTurkishCompanyName')} placement="top">
                            <button className='form-tooltip'><i className="bi bi-question-circle-fill"></i></button>
                        </Tippy>
                    </label>
                    <input type="text" className="form-control" id="businessTurkishCompanyName" placeholder="" value={data.businessTurkishCompanyName} onChange={handleInputChange('businessTurkishCompanyName')} />
                </div>
                <div className='col-lg-6 mb-3'>
                    <label htmlFor="businessTurkishCompanyAddress" className="form-label">{t('visaFormPage.label29')}
                        <Tippy content={t('tooltip.businessTurkishCompanyAddress')} placement="top">
                            <button className='form-tooltip'><i className="bi bi-question-circle-fill"></i></button>
                        </Tippy>
                    </label>
                    <input type="text" className="form-control" id="businessTurkishCompanyAddress" placeholder="" value={data.businessTurkishCompanyAddress} onChange={handleInputChange('businessTurkishCompanyAddress')} />
                </div>
                <div className='col-lg-6 mb-3'>
                    <label htmlFor="businessAlgeriaCompanyName" className="form-label"> {t('visaFormPage.label30')}</label>
                    <input type="text" className="form-control" id="businessAlgeriaCompanyName" value={data.businessAlgeriaCompanyName} onChange={handleInputChange('businessAlgeriaCompanyName')} />
                </div>
                <div className='col-lg-6 mb-3'>
                    <label htmlFor="businessAlgeriaCompanyAddress" className="form-label">{t('visaFormPage.label31')}
                    </label>
                    <input type="text" className="form-control" id="businessAlgeriaCompanyAddress" value={data.businessAlgeriaCompanyAddress} onChange={handleInputChange('businessAlgeriaCompanyAddress')} />
                </div>
                <div className='col-lg-6 mb-3'>
                    <label htmlFor="businessJob" className="form-label">{t('visaFormPage.label32')}</label>
                    <input type="text" className="form-control" id="businessJob" value={data.businessJob} onChange={handleInputChange('businessJob')} />
                </div>
                <div className='col-lg-6 mb-3'>
                    <label htmlFor="businessTitle" className="form-label">{t('visaFormPage.label33')}</label>
                    <input type="text" className="form-control" id="businessTitle" value={data.businessTitle} onChange={handleInputChange('businessTitle')} />
                </div>
                <div className='col-lg-6 mb-3'>
                    <label htmlFor="businessVisaDuration" className="form-label">{t('visaFormPage.label34')}</label>
                    <select id='businessVisaDuration' className="form-select" aria-label="Default select businessVisaDuration" value={data.businessVisaDuration} onChange={handleInputChange('businessVisaDuration')}>
                        <option selected>{t('visaFormPage.select')}</option>
                        <option value="1">{t('visaFormPage.1Month')}</option>
                        <option value="2">{t('visaFormPage.3Months')}</option>
                    </select>
                </div>
                <div className='col-lg-6 mb-3'>
                    <label htmlFor="businessVisaStartDate" className="form-label">{t('visaFormPage.label35')}
                        <Tippy content={t('tooltip.businessVisaStartDate')} placement="top">
                            <button className='form-tooltip'><i className="bi bi-question-circle-fill"></i></button>
                        </Tippy>
                    </label>
                    <input type="text" className="form-control" id="businessVisaStartDate" value={moment(data.businessVisaStartDate).format('YYYY-MM-DD')} onChange={handleInputChange('businessVisaStartDate')} />
                </div>
                <div className='col-lg-6 mb-3'>
                    <label htmlFor="sgkServiceStatement" className="form-label">{t('visaFormPage.label36')}</label>
                    <FileUploader onChange={handleDrop('sgkServiceStatement')} label={t('visaFormPage.fileUploadTitle')} />
                </div>
                <div className='col-lg-6 mb-3'>
                    <label htmlFor="businessCompanyInvitation" className="form-label">{t('visaFormPage.label37')}
                        <Tippy content={t('tooltip.businessCompanyInvitation')} placement="top">
                            <button className='form-tooltip'><i className="bi bi-question-circle-fill"></i></button>
                        </Tippy>
                    </label>
                    <FileUploader onChange={handleDrop('businessCompanyInvitation')} label={t('visaFormPage.fileUploadTitle')} />
                </div>
                <div className='col-lg-6 mb-3'>
                    <label htmlFor="businessUndertakingLetter" className="form-label">{t('visaFormPage.label38')}
                        <Tippy content={t('tooltip.businessUndertakingLetter')} placement="top">
                            <button className='form-tooltip'><i className="bi bi-question-circle-fill"></i></button>
                        </Tippy>
                    </label>
                    <FileUploader onChange={handleDrop('businessUndertakingLetter')} label={t('visaFormPage.fileUploadTitle')} />
                </div>
                <div className='col-lg-6 mb-3'>
                    <label htmlFor="businessAlgerianCompanyDocs" className="form-label">{t('visaFormPage.label39')}
                        <Tippy content={t('tooltip.businessAlgerianCompanyDocs')} placement="top">
                            <button className='form-tooltip'><i className="bi bi-question-circle-fill"></i></button>
                        </Tippy>
                    </label>
                    <FileUploader onChange={handleDrop('businessAlgerianCompanyDocs')} label={t('visaFormPage.fileUploadTitle')} />
                </div>
                <div className='col-lg-12 mb-3'>
                    <label htmlFor="businessTurkishCompanyDocs" className="form-label">{t('visaFormPage.label40')}
                        <Tippy content={t('tooltip.businessTurkishCompanyDocs')} placement="top">
                            <button className='form-tooltip'><i className="bi bi-question-circle-fill"></i></button>
                        </Tippy>
                    </label>
                    <FileUploader onChange={handleDrop('businessTurkishCompanyDocs')} label={t('visaFormPage.fileUploadTitle')} />
                </div>
            </div>
        </div>
    );
};

export default BusinessVisaForm;