import React, { ReactNode } from 'react';
import ScreenType from "./ScreenType";

const MOBILE_MAX_WIDTH = 1179;

interface ResponsiveProps {
    children: ReactNode;
}

const Mobile = ({ children }: ResponsiveProps) => (
    <ScreenType query={{ maxWidth: MOBILE_MAX_WIDTH }}>
        {children}
    </ScreenType>
);

export default Mobile;
