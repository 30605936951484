import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import ContactSection from "../components/ContactSection";
import Form from "../components/Form";

const GDPR: React.FC = () => {
    const { t } = useTranslation(); // useTranslation hook'unu kullanarak 't' fonksiyonuna eriş

    return (
        <>
            <Helmet>
                <title>{t('GDPR')}</title>
                <meta name="keywords" content="{t('GDPR')}" />
            </Helmet>
            <section className='breadbrumb-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 breadcrumb-container'>
                            <h1>{t('GDPRText')}</h1>
                            <ul className="breadcrumbs">
                                <li><a href="/">{t('home')}</a></li>
                                <li>{t('GDPR')}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className='content-style-01 policy-content py-6'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>

                            <p>{t('gdpr.p1')}{t('gdpr.p1')}</p>
                            <p><b>{t('gdpr.p2')}</b></p>
                            <p>{t('gdpr.p3')}</p>
                            <p>{t('gdpr.p4')}</p>
                            <h2>{t('gdpr.h2-1')}</h2>
                            <p>{t('gdpr.p5')}</p>
                            <p><b>{t('gdpr.p6')}</b></p>
                            <p>{t('gdpr.p7')}</p>
                            <h2>{t('gdpr.h2-2')}</h2>
                            <p>{t('gdpr.p8')}</p>
                            <ul>
                                <li>{t('gdpr.p9')}</li>
                                <li>{t('gdpr.p10')}</li>
                                <li>{t('gdpr.p11')}</li>
                                <li>{t('gdpr.p12')}</li>
                            </ul>
                            <h2>{t('gdpr.h2-3')}</h2>
                            <p>{t('gdpr.p13')}</p>
                            <h2>{t('gdpr.h2-4')}</h2>
                            <p>{t('gdpr.p14')}</p>
                            <p>{t('gdpr.p15')}</p>
                            <p>{t('gdpr.p16')}</p>
                            <p>{t('gdpr.p17')}</p>
                            <p>{t('gdpr.p18')}</p>
                            <h2>{t('gdpr.h2-5')}</h2>
                            <p>{t('gdpr.p19')}</p>
                            <p>{t('gdpr.p20')}</p>
                            <p className='mb-0'>{t('gdpr.p21')}</p>
                            <p className='mb-0'>{t('gdpr.p22')}</p>
                            <p className='mb-0'>{t('gdpr.p23')}</p>
                            <p className='mb-0'>{t('gdpr.p24')}</p>
                            <p className='mb-0'>{t('gdpr.p25')}</p>
                            <p className='mb-0'>{t('gdpr.p26')}</p>
                            <p className='mb-0'>{t('gdpr.p27')}</p>
                            <p className='mb-0'>{t('gdpr.p28')}</p>
                            <p>{t('gdpr.p29')}</p>
                            <p>{t('gdpr.p30')}</p>
                            <p>{t('gdpr.p31')}</p>
                            <p>{t('gdpr.p32')}</p>
                            <p>{t('gdpr.p33')}</p>
                            <p>{t('gdpr.p34')}</p>
                            <p>{t('gdpr.p35')}</p>
                            <p>{t('gdpr.p36')}</p>
                            <p><b>{t('gdpr.p37')} <a href='/ilgili_kisi-basvuru-formu.pdf' target='_blank' title={t('gdpr.p38')}>{t('gdpr.p38')}</a></b></p>


                        </div>

                    </div>
                </div>
            </section>



            <ContactSection />
        </>
    )
}

export default GDPR;
