import React from 'react';
import { useTranslation } from 'react-i18next';
import { VisaType } from '../../models/enums/VisaType';

interface VisaTypeSelectionProps {
    visaType: string;
    handleVisaTypeChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const VisaTypeSelection: React.FC<VisaTypeSelectionProps> = ({ visaType, handleVisaTypeChange }) => {
    const { t } = useTranslation();

    return (
        <div className='form-type mb-4'>
            <div className='form-box'>
                <h2><span className='step'></span>{t('visaFormPage.selectVisaType')}</h2>
                <p>{t('visaFormPage.visaTypeInfoMessage')}</p>
                <label className='form-label' htmlFor="visaType">{t('visaFormPage.selectVisaType')}</label>
                <select className='form-control' id="visaType" value={visaType} onChange={handleVisaTypeChange}>
                    <option value="">{t('visaFormPage.select')}</option>
                    {Object.entries(VisaType).map(([type, value]) => (
                        <option key={value} value={value}>{t(`${value}Visa`)}</option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default VisaTypeSelection;