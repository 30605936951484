import { AxiosResponse } from "axios";
import apiClient from "../http/apiClient";
import { BaseService } from "./BaseService";

export class UserService extends BaseService {
    constructor(props?: any) {
        super(props);
    }

    async checkIfUserExists(username: string): Promise<any> {
        try {
            const response: AxiosResponse = await apiClient.get(
                `${process.env.REACT_APP_BASE_URL}/user/exists/${username}`
            );
            if (response.status === 200) {
                return Promise.resolve(response.data);
            }
        } catch (e) {
            return Promise.reject('Failed to fetch replies');
        }
        return Promise.reject('Failed to fetch replies');
    }

    async fetchAccount(): Promise<any> {
        try {
            const response: AxiosResponse = await apiClient.get(
                `${process.env.REACT_APP_BASE_URL}/user/me`
            );
            if (response.status === 200) {
                return Promise.resolve(response.data);
            }
        } catch (e) {
            return Promise.reject('Failed to fetch replies');
        }
        return Promise.reject('Failed to fetch replies');
    }
    
    async delete(): Promise<any> {
        try {
            const response: AxiosResponse = await apiClient.get(
                `${process.env.REACT_APP_BASE_URL}/user/me`
            );
            if (response.status === 200) {
                return Promise.resolve(response.data);
            }
        } catch (e) {
            return Promise.reject('Failed to fetch replies');
        }
        return Promise.reject('Failed to fetch replies');
    }

}
