import React, {useState, ChangeEvent, FormEvent} from 'react';
import { useTranslation } from 'react-i18next';

const Form: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [website, setWebsite] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // try {
        //     const response = await fetch('http://localhost:5000/send-email', {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //         },
        //         body: JSON.stringify({ email, name, website, message }),
        //     });
        //
        //     if (response.ok) {
        //         setSubmitted(true);
        //     } else {
        //         setError('Gönderim hatası: ' + response.statusText);
        //     }
        // } catch (error) {
        //     setError('Gönderim hatası: ' + error.toString());
        // }
    };

    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };

    const handleWebsiteChange = (e: ChangeEvent<HTMLInputElement>) => {
        setWebsite(e.target.value);
    };

    const handleMessageChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(e.target.value);
    };
    const { t } = useTranslation(); // useTranslation hook'unu kullanarak 't' fonksiyonuna eriş
    return (


        <div className="App">
            {submitted ? (
                <div>Teşekkürler! Email'iniz alındı.</div>
            ) : (
                <form onSubmit={handleSubmit}>
                    <div className='row'>
                        <div className='col-lg-6 mb-3'>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={name}
                                onChange={handleNameChange}
                                placeholder={t('fullname')} 
                                className='form-control'
                                required
                            />
                        </div>

                        <div className='col-lg-6 mb-3'>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={email}
                                onChange={handleEmailChange}
                                placeholder={t('email')} 
                                className='form-control'
                                required
                            />
                        </div>

                        <div className='col-lg-12 mb-3'>
                            <input
                                type="text"
                                id="website"
                                name="website"
                                value={website}
                                onChange={handleWebsiteChange}
                                placeholder={t('phone')} 
                                className='form-control'
                                required
                            />
                        </div>
                        <div className='col-lg-12 mb-3'>
                            <textarea
                                id="message"
                                name="message"
                                rows={8}
                                value={message}
                                onChange={handleMessageChange}
                                placeholder={t('yourMessage')} 
                                className='form-control'
                                required
                            />
                        </div>
                    </div>
                    <button type="submit" className='button-default mt-4'>{t('sendMessage')}</button>
                </form>
            )}
            {error && <div>{error}</div>}
        </div>
    );
};

export default Form;
