import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
// import Form from '../components/form';
// import ContactSection from '../components/contact-section';
import banner2 from '../assets/banner-2.jpg';
import ContactSection from "../components/ContactSection";
import Form from "../components/Form";

const About: React.FC = () => {
    const { t } = useTranslation(); // useTranslation hook'unu kullanarak 't' fonksiyonuna eriş

    return (
        <>
            <Helmet>
                <title>Be Partners Hakkında</title>
                <meta name="keywords" content="Be Partners Kurumsal" />
            </Helmet>
            <section className='breadbrumb-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 breadcrumb-container'>
                            <h1>{t('beVisa')}</h1>
                            <ul className="breadcrumbs">
                                <li><a href="/static">{t('home')}</a></li>
                                <li>{t('aboutUs')}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className='content-style-01 py-6'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-7'>
                            <h5>{t('experiencedTeam')}</h5>
                            <h2>{t('whoWeAre')}</h2>
                            <p>
                                {t('aboutUsParagraph1')}
                            </p>
                            <p>
                                {t('aboutUsParagraph2')}
                            </p>
                            <p>
                                {t('aboutUsParagraph3')}
                            </p>
                            <p>
                                {t('aboutUsParagraph4')}
                            </p>

                        </div>
                        <div className='col-lg-5'>
                            <img className='img-fluid radius' src={banner2} title={t('beVisa')} alt={t('beVisa')} />
                        </div>
                    </div>
                </div>
            </section>

            <section className='form-section mb-5 pb-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='content-style-01'>
                                <h5>{t('haveAQuestion')}</h5>
                                <h2>{t('writeToUs')}</h2>
                            </div>
                            <Form />
                        </div>
                    </div>
                </div>
            </section>

            <ContactSection />
        </>
    )
}

export default About;
