import React, { ReactNode, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from "react-router-dom";
import logo from '../assets/logo.png';
import LanguageSwitcher from '../components/LangSwitcher';
import ScrollToTopButton from "../components/Scroll";
import { useSessionStore } from '../stores/sessionStore';
import Desktop from './Desktop';
import Mobile from './Mobile';

import { useTranslation } from 'react-i18next';
import 'i18next'; // i18n yapılandırmasını ekle

const Header: React.FC = () => {
    const sessionStore = useSessionStore();

    const [scroll, setScroll] = useState<boolean>(false);
    const location = useLocation();
    const navigate = useNavigate();


    useEffect(() => {
        const handleScroll = () => {
            setScroll(window.scrollY > 50);
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const getClassName = (path: string, hasDropdown: boolean): string => {
        return `nav-link ${hasDropdown ? 'dropdown-toggle' : ''} ${location.pathname === path ? 'active' : ''}`.trim();
    };

    const handleDropdownClick = (event: React.MouseEvent<HTMLAnchorElement>): void => {
        const target = event.currentTarget;
        const href = target.getAttribute('href');
        if (href !== '#' && href) {
            // Navigate to the link
            window.location.href = href;
        }
    };

    const { t } = useTranslation(); // useTranslation hook'u burada kullanılacak

    return (
        <>
            <header>
                <div className={scroll ? "sticky sticky-fixed" : "sticky"}>
                    <div className='container'>
                        <div className='row g-0 align-items-center justify-content-between'>
                            <div className='col-lg-2 col-6'>
                                <a href='/' className='logo' title={t('beVisa')}>
                                    <img src={logo} title={t('beVisa')}
                                        alt={t('beVisa')} />
                                </a>
                            </div>
                            <Desktop>
                                <div className='col-lg-6'>
                                    <nav id="main-nav" className="main-menu navbar navbar-expand-lg">
                                        <div className="collapse navbar-collapse" id="navbarMain">
                                            <ul className="navbar-nav">
                                                <li className="nav-item">
                                                    <a href="/" className={getClassName('/', false)}>{t('home')}</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="/about-us"
                                                        className={getClassName('/about-us', false)}>{t('aboutUs')}</a>
                                                </li>
                                                <li className="nav-item dropdown">
                                                    <a href="/ticari-vize" className="nav-link dropdown-toggle"
                                                        role="button" data-bs-toggle="dropdown" aria-expanded="false"
                                                        onClick={handleDropdownClick}>{t('visas')}</a>
                                                    <ul className="dropdown-menu">
                                                        <li><a className="dropdown-item" href="/ticari-vize">{t('businessVisa')}</a></li>
                                                        <li><a className="dropdown-item" href="/turistik-vize">{t('touristVisa')}</a></li>
                                                        <li><a className="dropdown-item" href="/aile-vizesi">{t('familyVisa')}</a></li>
                                                        <li><a className="dropdown-item" href="/calisma-vizesi">{t('workVisa')}</a></li>
                                                        <li><a className="dropdown-item" href="/transit-vize">{t('transitVisa')}</a></li>
                                                    </ul>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="/faqs" className={getClassName('/faqs', false)}>{t('faqs')}</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="/contact"
                                                        className={getClassName('/contact', false)}>{t('contact')}</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                            </Desktop>
                            <Mobile>
                                <div className="offcanvas offcanvas-start sidebar" id="sideBar"
                                    aria-labelledby="sidebarLabel">
                                    <div className="offcanvas-header d-flex justify-content-between">
                                        <h5 className="offcanvas-title" id="sidebarLabel">Menu</h5>
                                        <button type="button" className="button-close" data-bs-dismiss="offcanvas"
                                            aria-label="Close"><i className="bi bi-x-lg"></i></button>
                                    </div>
                                    <div className="offcanvas-body">
                                        <nav id="main-nav" className="sidebar-menu navbar navbar-expand">
                                            <div className="collapse navbar-collapse" id="navbarMain">
                                                <ul className="navbar-nav">
                                                    <li className="nav-item">
                                                        <a href="/static" className={getClassName('/', false)}>Anasayfa</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a href="/about-us"
                                                            className={getClassName('/about-us', false)}>Hakkımızda</a>
                                                    </li>
                                                    <li className="nav-item dropdown">
                                                        <a href="/ticari-vize" className="nav-link dropdown-toggle"
                                                            role="button" data-bs-toggle="dropdown"
                                                            aria-expanded="false">Vizeler</a>
                                                        <ul className="dropdown-menu">
                                                            <li><a className="dropdown-item" href="/ticari-vize">Ticari
                                                                Vize</a></li>
                                                            <li><a className="dropdown-item" href="/turistik-vize">Turistik
                                                                Vize</a></li>
                                                            <li><a className="dropdown-item" href="/aile-vizesi">Aile
                                                                Vizesi</a></li>
                                                            <li><a className="dropdown-item" href="/calisma-vizesi">Çalışma
                                                                Vizesi</a></li>
                                                            <li><a className="dropdown-item" href="/transit-vize">Transit
                                                                Vize</a></li>
                                                        </ul>
                                                    </li>

                                                    <li className="nav-item">
                                                        <a href="/faqs" className={getClassName('/faqs', false)}>Sık
                                                            Sorulanlar</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a href="/contact"
                                                            className={getClassName('/contact', false)}>İletişim</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </nav>
                                    </div>
                                </div>
                            </Mobile>
                            <div className='col-lg-3 col-6 d-flex justify-content-end align-items-center'>
                                <LanguageSwitcher></LanguageSwitcher>
                                <Mobile>
                                    <button className="navbar-toggler" name="Sidebar" type="button"
                                        data-bs-toggle="offcanvas" data-bs-target="#sideBar"
                                        aria-controls="sideBar">
                                        <i className="bi bi-text-left"></i>
                                    </button>
                                </Mobile>
                                <Desktop>
                                    {
                                        !sessionStore.hasSession() &&
                                        <a href="/login" title='Hesabım' className="button-default">{t('login')}</a>
                                    }
                                    {
                                        sessionStore.hasSession() &&
                                        <a href="/basvurular" title='Hesabım' className="button-default">{t('account')}</a>
                                    }
                                </Desktop>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTopButton />
            </header>
        </>
    );
}

export default Header;
