import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import ContactSection from "../components/ContactSection";
// import Form from "../components/Form";

const MembershipAgreement: React.FC = () => {
    const { t } = useTranslation(); // useTranslation hook'unu kullanarak 't' fonksiyonuna eriş

    return (
        <>
            <Helmet>
                <title>{t('membershipAgreement.membershipAgreement')}</title>
                <meta name="keywords" content={t('membershipAgreement.membershipAgreement')} />
            </Helmet>
            <section className='breadbrumb-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 breadcrumb-container'>
                            <h1>{t('membershipAgreement.membershipAgreement')}</h1>
                            <ul className="breadcrumbs">
                                <li><a href="/">{t('home')}</a></li>
                                <li>{t('membershipAgreement.membershipAgreement')}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className='content-style-01 policy-content py-6'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <p>{t('membershipAgreement.p1')}
                             </p>
                            <p>{t('membershipAgreement.p2')}</p>
                            <p>{t('membershipAgreement.p3')}</p>
                            <h2>{t('membershipAgreement.h2-1')}</h2>
                            <p>{t('membershipAgreement.p4')}</p>
                            <h2>{t('membershipAgreement.h2-2')}</h2>
                            <p className='mb-0'>{t('membershipAgreement.p5')}</p>
                            <p className='mb-0'>{t('membershipAgreement.p6')}</p>
                            <p className='mb-0'>{t('membershipAgreement.p7')}</p>
                            <p className='mb-0'>{t('membershipAgreement.p8')}.</p>
                            <p>{t('membershipAgreement.p9')}</p>
                            <h2>{t('membershipAgreement.h2-3')}</h2>
                            <p>{t('membershipAgreement.p10')}</p>
                            <h2>{t('membershipAgreement.h2-4')}</h2>
                            <h3>{t('membershipAgreement.h2-5')}</h3>
                            <p>{t('membershipAgreement.p11')}</p>
                            <p>{t('membershipAgreement.p12')}</p>
                            <p>{t('membershipAgreement.p13')}</p>
                            <p>{t('membershipAgreement.p14')}</p>
                            <p>{t('membershipAgreement.p15')}</p>
                            <p>{t('membershipAgreement.p16')}</p>
                            <p>{t('membershipAgreement.p17')}</p>
                            <p>{t('membershipAgreement.p18')}</p>
                            <p>{t('membershipAgreement.p19')}</p>
                            <p>{t('membershipAgreement.p20')}</p>
                            <p>{t('membershipAgreement.p21')}</p>
                            <p>{t('membershipAgreement.p22')}</p>
                            <p>{t('membershipAgreement.p23')}</p>
                            <p>{t('membershipAgreement.p24')}</p>
                            <p>{t('membershipAgreement.p25')}</p>
                            <p>{t('membershipAgreement.p26')}</p>
                            <p>{t('membershipAgreement.p27')}</p>
                            <p>{t('membershipAgreement.p28')}</p>
                            <p>{t('membershipAgreement.p29')}</p>
                            <p>{t('membershipAgreement.p30')}</p>
                            <p>{t('membershipAgreement.p31')}</p>
                            <p>{t('membershipAgreement.p32')}</p>
                            <h3>{t('membershipAgreement.h2-6')}</h3>
                            <p>{t('membershipAgreement.p33')}</p>
                            <p>{t('membershipAgreement.p34')}</p>
                            <p>{t('membershipAgreement.p35')}</p>
                            <p>{t('membershipAgreement.p36')}</p>
                            <p>{t('membershipAgreement.p37')}</p>
                            <p>{t('membershipAgreement.p38')}</p>
                            <p>{t('membershipAgreement.p39')} </p>
                            <p>{t('membershipAgreement.p40')}</p>
                            <p>{t('membershipAgreement.p41')}</p>
                            <p>{t('membershipAgreement.p42')}</p>
                            <p>{t('membershipAgreement.p43')}</p>
                            <p>{t('membershipAgreement.p44')}</p>
                            <p>{t('membershipAgreement.p45')}</p>
                            <p>{t('membershipAgreement.p46')}</p>
                            <p>{t('membershipAgreement.p47')}</p>
                            <p>{t('membershipAgreement.p48')}</p>
                            <p>{t('membershipAgreement.p49')}</p>
                            <h2>{t('membershipAgreement.h2-7')}</h2>
                            <p>{t('membershipAgreement.p50')} </p>
                            <p>{t('membershipAgreement.p51')}</p>
                            <p>{t('membershipAgreement.p52')}</p>
                            <p>{t('membershipAgreement.p53')}</p>
                            <h2>{t('membershipAgreement.h2-8')}</h2>
                            <p>{t('membershipAgreement.p54')}</p>
                            <p>{t('membershipAgreement.p55')}</p>
                            <p>{t('membershipAgreement.p56')}</p>
                            <p>{t('membershipAgreement.p57')}</p>
                            <p>{t('membershipAgreement.p58')}</p>
                            <p>{t('membershipAgreement.p59')}</p>
                            <h2>{t('membershipAgreement.h2-9')}</h2> 
                            <p>{t('membershipAgreement.p60')}</p>
                            <p>{t('membershipAgreement.p61')}</p>
                            <p>{t('membershipAgreement.p62')}</p>
                            <p>{t('membershipAgreement.p63')}</p>
                            <p>{t('membershipAgreement.p64')}</p>
                            <p>{t('membershipAgreement.p65')}</p>
                            <h2>{t('membershipAgreement.h2-10')}</h2>
                            <p>{t('membershipAgreement.p66')}</p>
                            <p>{t('membershipAgreement.p67')}</p>
                            <p>{t('membershipAgreement.p68')}</p>
                            <p>{t('membershipAgreement.p69')}</p>
                            <h2>{t('membershipAgreement.h2-11')}</h2>
                            <h3>{t('membershipAgreement.h2-12')}</h3>
                            <p>{t('membershipAgreement.p70')}</p>
                            <p>{t('membershipAgreement.p71')}</p>
                            <p>{t('membershipAgreement.p72')}</p>
                            <p>{t('membershipAgreement.p73')}</p>
                            <p>{t('membershipAgreement.p74')}</p>
                            <p>{t('membershipAgreement.p75')}</p>
                            <p>{t('membershipAgreement.p76')}</p>
                            <h3>{t('membershipAgreement.h2-13')}</h3>
                            <p>{t('membershipAgreement.p77')}</p>
                            <p>{t('membershipAgreement.p78')}</p>
                            <p>{t('membershipAgreement.p79')}</p>
                            <h3>{t('membershipAgreement.h2-14')}</h3>
                            <p>{t('membershipAgreement.p80')}</p>
                            <p>{t('membershipAgreement.p81')}</p>
                            <h3>{t('membershipAgreement.h2-15')}</h3>
                            <p>{t('membershipAgreement.p82')} </p>
                            <p>{t('membershipAgreement.p83')}</p>
                            <p><b>{t('membershipAgreement.p84')}</b></p>

                        
                        </div>
                    </div>
                </div>


            </section>


            <ContactSection />
        </>
    )
}

export default MembershipAgreement;
