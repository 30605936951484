import { BasicInformation } from './BasicInformation';
import { PassportInformation } from './PassportInformation';
import { VisaInfo } from './VisaInfo';
import { TouristVisaInformation } from './TouristVisaInformation';
import { FamilyVisaInformation } from './FamilyVisaInformation';
import { WorkVisaInformation } from './WorkVisaInformation';
import { BusinessVisaInformation } from './BusinessVisaInformation';
import { TransitVisaInformation } from './TransitVisaInformation';
import _ from 'lodash';
import { KeyValuePair } from './types/KeyValuePair';
import { KeyFilePair } from './types/KeyFilePair';

export class VisaApplication {
    visaType: string = '';
    basicInformation: BasicInformation = new BasicInformation();
    passportInformation: PassportInformation = new PassportInformation();
    visaInfo: VisaInfo = new VisaInfo();
    touristVisaInformation?: TouristVisaInformation;
    familyVisaInformation?: FamilyVisaInformation;
    workVisaInformation?: WorkVisaInformation;
    businessVisaInformation?: BusinessVisaInformation;
    transitVisaInformation?: TransitVisaInformation;

    public constructor(init?: Partial<VisaApplication>) {
        Object.assign(this, init);
    }

    validate(): string[] {
        const errors: string[] = [];

        // Validate visaType
        if (!this.visaType) {
            errors.push("Visa type is required");
        }

        // Validate baseInformation
        errors.push(...this.basicInformation.validate());

        // Validate passportInformation
        errors.push(...this.passportInformation.validate());

        // Validate visaInfo
        errors.push(...this.visaInfo.validate());

        // Validate optional visa information based on visaType
        if (this.visaType === 'tourist' && this.touristVisaInformation) {
            errors.push(...this.touristVisaInformation.validate());
        }

        if (this.visaType === 'family' && this.familyVisaInformation) {
            errors.push(...this.familyVisaInformation.validate());
        }

        if (this.visaType === 'work' && this.workVisaInformation) {
            errors.push(...this.workVisaInformation.validate());
        }

        if (this.visaType === 'business' && this.businessVisaInformation) {
            errors.push(...this.businessVisaInformation.validate());
        }

        if (this.visaType === 'transit' && this.transitVisaInformation) {
            errors.push(...this.transitVisaInformation.validate());
        }

        return errors;
    }

    static randomVisaApplication(): VisaApplication {
        return new VisaApplication({
            visaType: 'tourist',
            basicInformation: BasicInformation.randomBasicInfo(),
            passportInformation: PassportInformation.randomPassportInfo(),
            visaInfo: VisaInfo.randomVisaInfo(),
            touristVisaInformation: TouristVisaInformation.randomTouristVisaInfo(),
            familyVisaInformation: FamilyVisaInformation.randomFamilyVisaInfo(),
            workVisaInformation: WorkVisaInformation.randomWorkVisaInfo(),
            businessVisaInformation: BusinessVisaInformation.randomBusinessVisaInfo(),
            transitVisaInformation: TransitVisaInformation.randomTransitVisaInfo()
        });
    }

    async toObject(): Promise<any> {
        const files = await this.files();
        return { 
            visaType: this.visaType,
            basicInformation: this.basicInformation.toObject(),
            passportInformation: this.passportInformation.toObject(),
            visaInfo: this.visaInfo.toObject(),
            touristVisaInformation: this.touristVisaInformation?.toObject(),
            familyVisaInformation: this.familyVisaInformation?.toObject(),
            workVisaInformation: this.workVisaInformation?.toObject(),
            businessVisaInformation: this.businessVisaInformation?.toObject(),
            transitVisaInformation: this.transitVisaInformation?.toObject(),
            files
        }
    }

    async files(): Promise<KeyFilePair[]> {
        let files: KeyFilePair[] = [];
    
        // Collect files from basicInformation
        files = _.concat(files, (await this.basicInformation.files()).map((pair) => new KeyFilePair(`basicInformation.${pair.key}`, pair.files)));
    
        // Collect files from passportInformation
        files = _.concat(files, (await this.passportInformation.files()).map((pair) => new KeyFilePair(`passportInformation.${pair.key}`, pair.files)));
    
        // Collect files from visaInfo
        files = _.concat(files, (await this.visaInfo.files()).map((pair) => new KeyFilePair(`visaInfo.${pair.key}`, pair.files)));
    
        // Collect files from optional visa information based on visaType
        if (this.visaType === 'tourist' && this.touristVisaInformation) {
            files = _.concat(files, (await this.touristVisaInformation.files()).map((pair) => new KeyFilePair(`touristVisaInformation.${pair.key}`, pair.files)));
        }
    
        if (this.visaType === 'family' && this.familyVisaInformation) {
            files = _.concat(files, (await this.familyVisaInformation.files()).map((pair) => new KeyFilePair(`familyVisaInformation.${pair.key}`, pair.files)));
        }
    
        if (this.visaType === 'work' && this.workVisaInformation) {
            files = _.concat(files, (await this.workVisaInformation.files()).map((pair) => new KeyFilePair(`workVisaInformation.${pair.key}`, pair.files)));
        }
    
        if (this.visaType === 'business' && this.businessVisaInformation) {
            files = _.concat(files, (await this.businessVisaInformation.files()).map((pair) => new KeyFilePair(`businessVisaInformation.${pair.key}`, pair.files)));
        }
    
        if (this.visaType === 'transit' && this.transitVisaInformation) {
            files = _.concat(files, (await this.transitVisaInformation.files()).map((pair) => new KeyFilePair(`transitVisaInformation.${pair.key}`, pair.files)));
        }
    
        return files.filter((pair) => pair.files.length > 0);
    }
}