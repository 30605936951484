import { AxiosResponse } from "axios";
import apiClient from "../http/apiClient";
import { BaseService } from "./BaseService";

export class AuthenticationService extends BaseService {

    constructor(props?: any) {
        super(props);
    }

    async login(email: string, password: string): Promise<any> {
        try {
            let response: AxiosResponse = await apiClient.post(`${process.env.REACT_APP_BASE_URL}/auth/login`, {
                email,
                password
            });
            if (response.status === 201) {
                return Promise.resolve(response.data);
            }
        } catch (e) {
            return Promise.reject('failed request');
        }
        return Promise.reject('failed request');
    }

    async register(email: string, password: string): Promise<any> {
        try {
            let response: AxiosResponse | any = await apiClient.post(`${process.env.REACT_APP_BASE_URL}/auth/register`, {
                email,
                password
            });
            if (response.status === 201) {
                return Promise.resolve(response.data);
            }else{
                return Promise.resolve(response.message);
            }
        } catch (e: any) {
            return Promise.resolve(e?.message);
        }
        return Promise.resolve('failed request');
    }

    async changePassword(oldPassword: string, newPassword: string): Promise<any> {
        try {
            let response: AxiosResponse = await apiClient.post(`${process.env.REACT_APP_BASE_URL}/auth/change-password`, {
                oldPassword,
                newPassword
            });
            if (response.status === 201) {
                return Promise.resolve(response.data);
            }
        } catch (e) {
            return Promise.reject('failed request');
        }
        return Promise.reject('failed request');
    }

    async changeUsername(newUsername: string): Promise<any> {
        try {
            let response: AxiosResponse = await apiClient.post(`${process.env.REACT_APP_BASE_URL}/auth/change-username`, {
                newUsername
            });
            if (response.status === 201) {
                return Promise.resolve(response.data);
            }
        } catch (e) {
            return Promise.reject('failed request');
        }
        return Promise.reject('failed request');
    }
}
