import { faker } from '@faker-js/faker';
import { KeyFilePair } from './types/KeyFilePair';
import fileHelper from '../helpers/fileHelper';

export class TransitVisaInformation {
    destinationCountry!: string;
    destinationCountryVisa!: string;
    transitVisaStartDate!: string;
    destinationCountryVisaCopy!: File[];

    public constructor(init?: Partial<TransitVisaInformation>) {
        Object.assign(this, init);
    }

    validate(): string[] {
        const errors: string[] = [];

        if (!this.destinationCountry) {
            errors.push("Destination country is required");
        }

        if (!this.destinationCountryVisa) {
            errors.push("Destination country visa status is required");
        }

        if (!this.transitVisaStartDate) {
            errors.push("Transit visa start date is required");
        }

        return errors;
    }

    static randomTransitVisaInfo(): TransitVisaInformation {
        return new TransitVisaInformation({
            destinationCountry: faker.address.country(), // destinationCountry
            destinationCountryVisa: faker.random.alphaNumeric(10), // destinationCountryVisa
            transitVisaStartDate: faker.date.future().toISOString().split('T')[0], // transitVisaStartDate
            destinationCountryVisaCopy: [] // destinationCountryVisaCopy (empty array for simplicity)
        });
    }

    toObject(): any {
        return {
            destinationCountry: this.destinationCountry.trim(),
            destinationCountryVisa: this.destinationCountryVisa.trim(),
            transitVisaStartDate: this.transitVisaStartDate.trim(),
        }
    }

    async files(): Promise<KeyFilePair[]> {
        return [new KeyFilePair('destinationCountryVisaCopy', await fileHelper.convertFilesToBase64(this.destinationCountryVisaCopy))];
    }
}