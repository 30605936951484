import React from 'react';
import Form from '../../components/Form';
import { useTranslation } from 'react-i18next';
import ContactSection from '../../components/ContactSection';
import { Helmet } from 'react-helmet';

interface TouristicVisaProps {}

const TouristicVisa: React.FC<TouristicVisaProps> = (props) => {

    const { t } = useTranslation(); // useTranslation hook'unu kullanarak 't' fonksiyonuna eriş
    return (
        <>
            <Helmet>
                <title>{t('touristVisa')}</title>
                <meta name="keywords" content="Cezayir Turistik Vize" />
            </Helmet>
            <section className='breadbrumb-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 breadcrumb-container'>
                            <h1>{t('touristVisa')}</h1>
                            <ul className="breadcrumbs">
                                <li><a href="/">{t('home')}</a></li>
                                <li>{t('touristVisa')}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className='content-style-01 py-5 my-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h5>{t('applicationProcess')}</h5>
                            <h2>{t('whatIsATouristVisa')}</h2>
                           <p>
                           {t('touristTexts.p1')}                            
                           </p>
  <h3 className='mb-4'>                      
  {t('touristTexts.h3')}  
</h3>    
<ul>
    <li>
    {t('touristTexts.li1')}  
</li>
<li>
    {t('touristTexts.li2')}  
</li>
<li>
    {t('touristTexts.li3')}  
</li>
<li>
    {t('touristTexts.li4')}  
</li>
<li>
    {t('touristTexts.li5')}  
</li>
<li>
    {t('touristTexts.li6')}  
</li>
<li>
    {t('touristTexts.li7')}  
</li>

 </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className='form-section mb-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='content-style-01'>
                            <h5>{t('haveAQuestion')}</h5>
                            <h2>{t('writeToUs')}</h2>
                            </div>
                            <Form />
                        </div>
                    </div>
                </div>
            </section>

            <ContactSection />
        </>
    );
}

export default TouristicVisa;