import React, { useState } from 'react';
import Dropzone from 'react-dropzone';

interface FileUploaderProps {
    onChange: (files: File[]) => void;
    label: string;
}

const FileUploader: React.FC<FileUploaderProps> = ({ onChange, label }) => {
    const [files, setFiles] = useState<File[]>([]);

    const handleDrop = (acceptedFiles: File[]) => {
        setFiles(acceptedFiles);
        onChange(acceptedFiles);
    };

    return (
        <div>
            <Dropzone
                onDrop={handleDrop}
                accept={{
                    'image/*': [],
                    'application/pdf': []
                }}
            >
                {({ getRootProps, getInputProps }: any) => (
                    <section className='upload-row'>
                        <div {...getRootProps()} style={{ cursor: 'pointer' }}>
                            <input {...getInputProps()} />
                            <p>{label}</p>
                        </div>
                    </section>
                )}
            </Dropzone>

            {/* Preview of uploaded files */}
            <div style={{ display: 'flex', marginTop: '20px' }}>
                {files.map((file, index) => (
                    <div key={index} style={{ marginRight: '10px' }}>
                        {file.type.startsWith('image/') && (
                            <img
                                src={URL.createObjectURL(file)}
                                alt="preview"
                                style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                            />
                        )}
                        {file.type === 'application/pdf' && (
                            <p>{file.name}</p>  // Display the name of PDF files
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FileUploader;